import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Stack } from 'tamagui';
import Checkbox from '../Checkbox';
import { SelectProps } from './SelectProps';

const Select = (props: SelectProps) => {
    const getValue = () => {
        return props.items?.find((item) => item.value === props.value);
    };

    return (
        <Autocomplete
            options={props.items || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Stack mr={6}>
                        <Checkbox style={{ marginRight: 8 }} value={selected} />
                    </Stack>

                    {option.label}
                </li>
            )}
            renderInput={(params) => <TextField {...params} variant='outlined' fullWidth label={props.label} />}
            fullWidth
            onChange={(e, item) => props.onChange(item?.value)}
            value={getValue()}
        />
    );
};

export default Select;
