import { StateMessage, ViewStateProps } from './ViewStateProps';

function resolveState<T extends StateMessage>(state: boolean | T | undefined): T {
    if (!state)
        return {
            enabled: false,
        } as unknown as T;

    if (typeof state === 'boolean') {
        return {
            enabled: state,
        } as unknown as T;
    }

    return {
        ...state,
        enabled: state.enabled,
    } as T;
}

export function useViewState({ empty, error, loading, search }: ViewStateProps) {
    return {
        empty: resolveState(empty),
        error: resolveState(error),
        loading: resolveState(loading),
        search: resolveState(search),
    };
}
