import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import * as Location from 'expo-location';
import { useEffect, useState } from 'react';
import { useLogger } from '../../../../config/logging/useLogger';
import { useMDNS } from './useMDNS';
import { useSaveServerConfig } from './useSaveServerConfig';
import { useCurrentServerConfig } from './useCurrentServerConfig';
import { useTestServerConnection } from './useTestServerConnection';
import { CameraServerNetwork } from '@/chicken-house/entities/CameraServerNetwork';
import { Platform } from 'react-native';

type Props = {
    scanOnMount: boolean;
};

export function useServerScan(props?: Props) {
    const cameraServerStore = useCameraServer();

    const logger = useLogger();

    const serverConfig = useCurrentServerConfig();
    const saveServerConfig = useSaveServerConfig();

    const testCameraServer = useTestServerConnection();

    const mdnsScan = useMDNS();

    const [isScanning, setIsScanning] = useState(false);

    async function runScan() {
        try {
            if (Platform.OS === 'web') return;

            logger.info('start scanning local server', null, 'scan-server');

            setIsScanning(true);

            // location permission is necessary to get network ssid
            await Location.requestForegroundPermissionsAsync().catch(() => {
                logger.warn('location permission not grant');
            });

            let network: CameraServerNetwork | null = await serverConfig.getCurrentConfig();

            if (network) {
                const isServerAvailable = await testCameraServer.isReachable(network);

                if (!isServerAvailable) {
                    network = null;
                }
            }

            // if network not found on database, trigger mdns scan to
            // find it in local area network
            if (!network) {
                network = await mdnsScan.startScan();

                await saveServerConfig.mutateAsync(network);
            }

            cameraServerStore.onDnsScan(network);
        } catch (err) {
            logger.captureException(err);
        } finally {
            setIsScanning(false);
        }
    }

    useEffect(() => {
        if (props?.scanOnMount && !mdnsScan.isScanning) runScan();
    }, []);

    return { runScan, isScanning };
}
