import { Panel } from '@/shared/components/ui/Panel';
import Sense from '../Sense';
import { SenseReport } from '../SenseReport';

export const SenseManager = () => {
    return (
        <Panel>
            <Panel.Screen>
                <Sense />
            </Panel.Screen>
            <Panel.Screen>
                <SenseReport />
            </Panel.Screen>
        </Panel>
    );
};
