import { Linking, Platform } from 'react-native';
import { useLogger } from '../../../../config/logging/useLogger';

export function useOpenWifiSettings() {
    const logger = useLogger();

    async function goToWifiSettings() {
        try {
            if (Platform.OS === 'ios') {
                await Linking.openURL(`App-Prefs:root=WIFI`);
            }

            await Linking.sendIntent('android.settings.WIFI_SETTINGS');
        } catch (err) {
            logger.captureException(err);
        }
    }

    return {
        goToWifiSettings,
    };
}
