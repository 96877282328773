import React, { PropsWithChildren } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { Text } from 'tamagui';
import MultipleFilter from './MultipleFilter';

type Props = PropsWithChildren;

const Filters = (props: Props) => {
    return (
        <ScrollView
            horizontal
            contentContainerStyle={{ gap: 6, alignItems: 'center' }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={{ overflow: 'visible' }}
        >
            <Text fontSize={14} color='$secondary900' mr={4}>
                Filtrar por
            </Text>

            {props.children}
        </ScrollView>
    );
};

Filters.Multiple = MultipleFilter;

export default Filters;
