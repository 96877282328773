import { useCameraRecorder } from '@/camera-video/hooks/useCameraRecorder';
import { useRecordStore } from '@/camera-video/store/useRecordStore';
import Spinner from '@/shared/components/ui/Spinner';
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { YStack } from 'tamagui';
import RecordStopwatch from './RecordStopwatch/RecordStopwatch';
import { Touchable } from './styles';
import { useRecordAnimation } from './useRecordAnimation';

type Props = {
    url: string;
    onRecordSuccess: () => void;
};

const RecordButton = ({ url, onRecordSuccess }: Props) => {
    const status = useRecordStore((record) => record.status);
    const { isRecording, startRecording, stopRecording } = useCameraRecorder(url);
    const { opacity } = useRecordAnimation();

    function handleToggleRecording() {
        if (!isRecording) return startRecording();

        stopRecording().then(() => onRecordSuccess());
    }

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }));

    const isLoading = status === 'starting' || status === 'stopping';

    return (
        <Touchable onPress={handleToggleRecording} disabled={isLoading}>
            {isLoading && <Spinner size='small' color='#de0a26' />}

            {!isRecording && !isLoading && (
                <YStack ai='center'>
                    <FontAwesome5 name='video' size={22} color='#de0a26' />
                </YStack>
            )}

            {isRecording && !isLoading && (
                <>
                    <Animated.View style={animatedStyle}>
                        <FontAwesome5 name='stop' size={22} color='#de0a26' />
                    </Animated.View>

                    <RecordStopwatch />
                </>
            )}
        </Touchable>
    );
};

export default RecordButton;
