import { minBuildVersion } from '@/shared/utils/version';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { MMKV } from 'react-native-mmkv';

const storage = minBuildVersion('1.21.0', false) ? new MMKV({ id: '@alltis/cache-db' }) : undefined;

export const persister = createSyncStoragePersister({
    storage: {
        getItem: (key) => {
            return storage?.getString(key) || null;
        },
        removeItem: (key) => {
            return storage?.delete(key);
        },
        setItem: (key, value) => {
            return storage?.set(key, value);
        },
    },
});
