import MultiSelect from '@/shared/components/ui/MultiSelect';
import { Aviary } from '@/user/entities/Aviary';
import { Farm } from '@/user/entities/Farm';
import { useFarmAviaries } from '@/user/hooks/useFarmAviaries';
import { useFarms } from '@/user/hooks/useFarms';
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Separator, Stack, Text } from 'tamagui';

type Props = {
    farm: Farm | null;
    aviary: Aviary | null;
    onChangeFarm: (farm: Farm | null) => void;
    onChangeAviary: (aviary: Aviary | null) => void;
};

const FarmSelectorContent = ({ aviary, farm, onChangeAviary, onChangeFarm }: Props) => {
    const { data: farms, isLoading: isLoadingFarms } = useFarms();
    const { data: aviaries, isLoading: isLoadingAviaries } = useFarmAviaries(farm?.id);

    const farmItems = farms?.map((farm) => ({
        label: farm.name,
        value: farm.id,
    }));

    const aviariesItems = aviaries?.map((aviary) => ({
        label: aviary.name,
        value: aviary.id,
    }));

    return (
        <Stack px={16} py={12}>
            <Text fontSize={22} fontWeight='bold'>
                Granja e Aviário
            </Text>

            <Separator my={18} />

            <Stack>
                <MultiSelect
                    items={[{ label: 'Todas as granjas', value: null }, ...(farmItems || [])]}
                    icon={<FontAwesome5 name='map' />}
                    label='Granja'
                    sheetTitle='Selecione a granja'
                    single
                    onChange={(_, selected) => {
                        const item = selected?.[0];

                        if (item?.value) {
                            return onChangeFarm({ id: item.value, name: item.label });
                        }

                        onChangeFarm(null);
                        onChangeAviary(null);
                    }}
                    value={farm?.id ? [farm.id] : [null]}
                    isLoading={isLoadingFarms}
                />

                <Stack mt={12}>
                    <MultiSelect
                        items={[{ label: 'Todos os aviários', value: null }, ...(aviariesItems || [])]}
                        icon={<FontAwesome5 name='home' />}
                        label='Aviário'
                        sheetTitle='Selecione o aviário'
                        onChange={(_, selected) => {
                            const item = selected?.[0];

                            if (item) {
                                onChangeAviary(item.value ? { id: item.value, name: item.label } : null);
                            }
                        }}
                        single
                        value={aviary?.id && farm?.id ? [aviary.id] : [null]}
                        isDisabled={!farm?.id}
                        isLoading={isLoadingAviaries}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FarmSelectorContent;
