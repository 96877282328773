import { SenseMetrics } from '@/sense/entities/Sense';
import { useChartWidth } from '@/shared/components/ui/ScrollableChart/useChartWidth';
import { formatChartValue } from '@/shared/utils/charts';
import dayjs from 'dayjs';
import React from 'react';
import { Dimensions } from 'react-native';
import { VictoryChart, VictoryLegend, VictoryLine, VictoryScatter, VictoryTheme } from 'victory-native';
import { senseChartAxis } from './base/SenseChartAxis';

type Props = {
    metrics: Pick<SenseMetrics, 'relative_humidity' | 'timestamp'>[];
};

const SenseHumidityChart = (props: Props) => {
    const chartWidth = useChartWidth();

    const metrics = props.metrics.map((m) => ({
        ...m,
        relative_humidity: m.relative_humidity * 100,
    }));

    return (
        <VictoryChart
            width={chartWidth}
            theme={VictoryTheme.material}
            domainPadding={{
                x: Dimensions.get('screen').width / 20,
                y: 20,
            }}
            // minDomain={{
            //     y: RANGE_OPTIONS.humidity.range[0],
            // }}
            // maxDomain={{
            //     y: RANGE_OPTIONS.humidity.range[1],
            // }}
        >
            <VictoryLegend
                orientation='horizontal'
                x={40}
                data={[{ name: 'Umidade', symbol: { fill: '#5b7ae9' } }]}
                style={{ labels: { fontSize: 12 } }}
            />

            <VictoryLine
                data={metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.relative_humidity,
                }))}
                labels={({ datum }) => formatChartValue(datum.y, { prefix: '%' })}
                style={{ data: { stroke: '#5b7ae930' } }}
            />

            <VictoryScatter
                data={metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.relative_humidity,
                }))}
                labels={() => null}
                style={{ data: { fill: '#4649a4' } }}
            />

            {senseChartAxis.x}
            {senseChartAxis.y}
        </VictoryChart>
    );
};

export default SenseHumidityChart;
