import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import { useMutation } from '@tanstack/react-query';
import { cameraScanApi } from '../../api/config/cameraScanApi';
import { PlaceSetupForm } from '../../entities/PlaceSetupForm';
import { useCurrentPlace } from '../../store/CurrentPlaceStore';

type PlaceSetupRequest = Array<{
    stream_mac: string;
    stream_name: string;
    stream_url: string;
    ip: string;
    nucleo: string;
}>;

export function useCameraSetup() {
    const chickenHouses = useChickenHouses();
    const onPlaceNameReceived = useCurrentPlace((place) => place.onPlaceNameReceived);

    return useMutation({
        mutationFn: async (setup: PlaceSetupForm) => {
            const setupData: PlaceSetupRequest = setup.places
                .filter((setup) => !!setup?.name)
                .map((data) => ({
                    nucleo: setup.coreName,
                    stream_name: data.name,
                    stream_mac: data.source.streamMAC,
                    stream_url: data.source.url,
                    ip: data.source.ip,
                }));

            const response = await cameraScanApi.post<string>('/setup', setupData);

            onPlaceNameReceived(setup.coreName);

            return {
                message: response.data,
            };
        },

        onSuccess() {
            chickenHouses.refetch();
        },
    });
}
