import { PropsWithChildren, createContext } from 'react';

export type PanelContextType = {
    isPanel: boolean;
};

export const PanelContext = createContext<PanelContextType>({} as PanelContextType);

export const PanelProvider = (props: PropsWithChildren) => {
    return <PanelContext.Provider value={{ isPanel: true }}>{props.children}</PanelContext.Provider>;
};
