import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React from 'react';
import { DatePickerProps } from './DatePickerProps';

const DatePicker = (props: DatePickerProps) => {
    return (
        <MuiDateTimePicker
            label={props.label}
            minDate={props.minDate ? dayjs(props.minDate) : undefined}
            maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
            value={props.value ? dayjs(props.value) : undefined}
            onChange={(date) => props.onChange(date?.toDate())}
            format='DD/MM/YYYY HH:mm'
        />
    );
};

export default DatePicker;
