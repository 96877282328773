export const handleResponseNumber = (value: string | number | undefined, isPercentage = false) => {
    if (value === 'NaN' || !value) return 0;

    const converted = Number(value);

    if (!isPercentage) {
        return converted;
    }

    return converted;
};
