import { Alert } from 'react-native';
import { useAuth } from '../store/AuthStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type HandleLogoutProps = {
    hasConfirmation: boolean;
};

export function useLogout() {
    const authStore = useAuth();
    const queryClient = useQueryClient();

    const { isLoading, mutateAsync: logout } = useMutation(['logout'], async () => {
        await authStore.logout();

        queryClient.clear();
    });

    function handleLogout(options?: HandleLogoutProps) {
        if (options?.hasConfirmation) {
            return Alert.alert(
                'Sair da conta',
                'Deseja desconectar sua conta desse dispositivo?',
                [
                    {
                        text: 'Sim, sair da conta',
                        isPreferred: false,
                        style: 'destructive',
                        onPress: () => logout(),
                    },
                    {
                        isPreferred: false,
                        text: 'Cancelar',
                        style: 'cancel',
                    },
                ],
                {
                    cancelable: true,
                }
            );
        }

        return logout();
    }

    return { handleLogout, isLoading };
}
