import { injectable } from 'inversify';
import { Repository } from 'lib';
import { Q } from '../../../../config/database';
import { CameraRecordModel } from '../models/CameraRecordModel';

@injectable()
export class CameraRecordRepository extends Repository(CameraRecordModel) {
    findByCorePlace(placeId: string) {
        return this.model()
            .query(Q.where('core_place_id', Q.eq(placeId)))
            .fetch();
    }

    findAllSortByDateDesc() {
        return this.model().query(Q.sortBy('created_at', Q.desc)).fetch();
    }

    async deleteById(id: string) {
        const record = await this.model().find(id);
        record.destroyPermanently();
    }
}
