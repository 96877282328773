import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Toaster } from 'burnt/web';
import { PropsWithChildren, useMemo } from 'react';
import { useThemeConfig } from '../../../../../config/theme/tamagui.config';

export default function WebProvider(props: PropsWithChildren) {
    const themeConfig = useThemeConfig();

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        '500': themeConfig.tokens.color.primary500.val,
                    },
                },
            }),
        []
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {props.children}

                <Toaster position='bottom-center' />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
