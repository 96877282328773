import { injectable } from 'inversify';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { User } from '../entities/User';
import { AuthResponse } from './types/AuthResponse';

@injectable()
export class AuthApi {
    /**
     * Authenticate an user
     *
     * @param email - Email to authenticate
     * @param password - Password to authenticate
     *
     * @returns authenticated user information
     */
    async authenticate(email: string, password: string): Promise<AuthResponse> {
        const { data } = await alltisApiV2.post<AuthResponse>('/login', { email, password });

        return data;
    }

    /**
     * Get current user information
     */
    async getCurrentUser(): Promise<User> {
        const { data } = await alltisApiV2.get<User>('/user');

        return data;
    }
}
