import React from 'react';
import MultiSelectContent from '../../MultiSelect/Content';
import BaseFilter from '../Base/BaseFilter';
import FilterIndicator from '../Base/FilterIndicator';
import { FilterProps } from '../FilterProps';
import { useMultipleFilterState } from './useMultipleFilterState';

const MultipleFilter = (props: FilterProps) => {
    const { onSelect, value } = useMultipleFilterState(props.value);

    return (
        <BaseFilter
            trigger={<FilterIndicator active={(props.value?.length || 0) > 0} label={props.label} />}
            onConfirm={() => props.onConfirm?.(value)}
        >
            <MultiSelectContent
                label={'Filtrar por ' + props.label}
                items={props.items || []}
                onSelect={onSelect}
                value={value}
            />
        </BaseFilter>
    );
};

export default MultipleFilter;
