import { Aqua } from '@/aqua/entities/Aqua';
import { useAquaUnits } from '@/aqua/hooks/utils/useAquaUnits';
import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import WarehouseStatus from '@/warehouse/components/WarehouseStatus';
import dayjs from 'dayjs';
import React from 'react';
import { Stack, XStack } from 'tamagui';

type Props = {
    aqua: Aqua;
    resumed?: boolean;
};

const BasicAquaInfo = ({ aqua, resumed }: Props) => {
    const aquaUnits = useAquaUnits();

    return (
        <Stack>
            <XStack mb={resumed ? 0 : 16} alignItems='center'>
                <Stack width='50%'>
                    <WarehouseStatus deviceStatus={aqua.device_status || 'DISCONNECTED'} levelStatus='NORMAL' />
                </Stack>

                <LabelAndValue
                    label='Consumo médio diário'
                    value={aquaUnits.format(aqua.consumption.daily_average || 0)}
                    containerProps={{ width: '50%' }}
                />
            </XStack>

            {!resumed && (
                <XStack mb={16}>
                    <LabelAndValue
                        label='Pico de consumo'
                        value={aquaUnits.format(aqua.consumption.peak.value || 0)}
                        containerProps={{ width: '50%' }}
                    />

                    <LabelAndValue
                        label='Data do pico'
                        value={dayjs(aqua.consumption.peak.timestamp).format('DD [de] MMMM')}
                        containerProps={{ width: '50%' }}
                    />
                </XStack>
            )}
        </Stack>
    );
};

export default BasicAquaInfo;
