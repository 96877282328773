import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { InteractionManager } from 'react-native';

/**
 * Tracks when react-navigation screen transition starts and finishes.
 * It can be used to render or running long tasks after screen loads
 *
 * @param withDelay delay to disable loading
 */
export const useScreenLoading = (withDelay = 0) => {
    const [loading, setLoading] = useState(true);

    useFocusEffect(
        useCallback(() => {
            const handle = () => setLoading(false);

            const task = InteractionManager.runAfterInteractions(() => setTimeout(() => handle(), withDelay));

            setTimeout(() => handle(), 400);

            return () => task.cancel();
        }, [])
    );

    return { isLoading: loading };
};
