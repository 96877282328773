import { useQuery } from '@tanstack/react-query';
import { container } from '../../../config/di';
import { AuthApi } from '../api/AuthApi';
import { User } from '../entities/User';

export function useGetUser() {
    const authApi = container.resolve(AuthApi);

    return useQuery<User>({
        queryFn: () => authApi.getCurrentUser(),
    });
}
