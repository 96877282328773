import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import React from 'react';
import { Stack, Text } from 'tamagui';
import { BaseCard } from '../../shared/components/ui/BaseCard';

type Props = {
    title: string;
    items: Array<{
        label: string;
        value: string;
    }>;
};

const ReportResultCard = ({ title, items }: Props) => {
    return (
        <Stack p={14}>
            <BaseCard mb={12} cursor='initial'>
                <Text color='$secondary500' mb={14} fontSize={14} fontWeight='600'>
                    {title}
                </Text>

                <Stack flexDirection='row' $platform-native={{ flexDirection: 'column' }} gap={14} flexWrap='wrap'>
                    {items.map((item, index) => (
                        <LabelAndValue key={index} label={item.label} value={item.value} containerProps={{ flex: 1 }} />
                    ))}
                </Stack>
            </BaseCard>
        </Stack>
    );
};

export default ReportResultCard;
