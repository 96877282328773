import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { Aviary } from '../entities/Aviary';

export function useFarmAviaries(farmId: string | undefined) {
    return useQuery<Aviary[]>({
        queryKey: ['farm-aviaries', farmId],
        queryFn: async () => (await alltisApiV2.get(`/farms/${farmId}/aviary/list`)).data,
        enabled: !!farmId,
    });
}
