import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { MultiSelectItem, MultiSelectProps } from './MultiSelectProps';
import Checkbox from '../Checkbox';
import { Stack } from 'tamagui';

type Props = MultiSelectProps;

const MultiSelect = (props: Props) => {
    const getValue = () => {
        if (props.single) {
            return props.items?.find((item) => item.value === props.value[0]) as MultiSelectItem;
        }

        return props.value
            ?.map((v) => props.items?.find((item) => item.value === v))
            .filter((item) => !!item) as MultiSelectItem[];
    };

    return (
        <Autocomplete
            multiple={!props.single}
            options={props.items || []}
            disableCloseOnSelect={!props.single}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Stack mr={6}>
                        <Checkbox style={{ marginRight: 8 }} value={selected} />
                    </Stack>

                    {option.label}
                </li>
            )}
            loadingText='Carregando...'
            loading={props.isLoading}
            renderInput={(params) => (
                <TextField {...params} variant='outlined' fullWidth label={props.label} placeholder={props.sheetTitle} />
            )}
            fullWidth
            onChange={(e, items) => {
                if (Array.isArray(items)) {
                    return props.onChange(
                        items.map((i) => i.value),
                        items
                    );
                }

                return props.onChange(items ? [items.value] : [], items ? [items] : []);
            }}
            value={getValue()}
            disabled={props.isDisabled}
        />
    );
};

export default MultiSelect;
