import AppVersion from '@/shared/components/ui/AppVersion';
import Menu from '@/user/components/Profile/Menu';
import UserInformation from '@/user/components/Profile/UserInformation';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { Stack } from 'tamagui';

type Props = {
    disableUsername?: boolean;
};

const Profile = (props: Props) => {
    return (
        <>
            {!props.disableUsername && <UserInformation />}

            <ScrollView style={{ flex: 1 }}>
                <Menu />

                <Stack px={22} py={12}>
                    <AppVersion />
                </Stack>
            </ScrollView>
        </>
    );
};

export default Profile;
