import { useNavigation } from '@react-navigation/native';
import { SettingScreenRouteProps } from '..';

export function useSettingField() {
    const navigation = useNavigation();

    function open(params: SettingScreenRouteProps) {
        return navigation?.navigate('SettingFieldScreen', params);
    }

    return { open };
}
