import * as FileSystem from 'expo-file-system';
import * as IntentLauncher from 'expo-intent-launcher';
import { Platform } from 'react-native';
import FileViewer from 'react-native-file-viewer';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';

export function useOpenSystemVideo() {
    const errorHandler = useErrorHandler();

    async function open(uri: string) {
        try {
            if (Platform.OS === 'android') {
                const mp4Contents = await FileSystem.getContentUriAsync(uri);

                return IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                    data: mp4Contents,
                    flags: 1,
                    type: 'video/mp4',
                });
            }

            await FileViewer.open(uri);
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    return { open };
}
