import { LoadingButton } from '@mui/lab';
import React from 'react';
import { Stack } from 'tamagui';
import { ButtonProps } from './ButtonProps';

const Button = ({ loading, disabled, onPress, ...props }: ButtonProps) => {
    return (
        <Stack {...props}>
            <LoadingButton disabled={disabled} loading={loading} onClick={() => onPress()} variant={props.variant || 'contained'}>
                {props.children}
            </LoadingButton>
        </Stack>
    );
};

export default Button;
