import WarehouseHeader from '@/warehouse/components/WarehouseDetails/WarehouseHeader';
import { Silo } from '@/warehouse/entities/Silo';
import { useViewMapListener } from '@/warehouse/hooks/events/useViewMapEvent';
import { useSilos } from '@/warehouse/hooks/silos/useSilos';
import { useSiloPanel } from '@/warehouse/store/useSiloPanelStore';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import MapView, { Callout, MapMarker, Marker, MarkerPressEvent, Region } from 'react-native-maps';
import { Stack, Text } from 'tamagui';
import { expo } from '../../../../../app.json';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';

type Marker = MapMarker;

const WarehouseMaps = () => {
    const isLarge = useLargeLayout();
    const navigation = useNavigation();
    const mapViewRef = useRef<MapView>(null);
    const markerRefs = useRef<{ [key: string]: Marker | null }>({});

    const onSelectSilo = useSiloPanel((panel) => panel.onSelectSilo);

    const { data, isLoading } = useSilos();

    function fitToElements() {
        try {
            const padding =
                Platform.select({
                    ios: 100,
                    android: 30,
                }) || 30;

            const elements = data?.silos.map((silo) => ({
                latitude: silo.position.latitude,
                longitude: silo.position.longitude,
            }));

            mapViewRef.current?.fitToCoordinates?.(elements, {
                animated: true,
                edgePadding: {
                    top: padding,
                    bottom: padding,
                    left: padding,
                    right: padding,
                },
            });
        } catch (err) {
            //
        }
    }

    useViewMapListener((silo) => {
        if (!silo?.position) return null;

        const { latitude: lat, longitude: lng } = silo.position;

        if (!lat || !lng) return;

        const zoom = Platform.OS === 'web' ? 15 : 18;

        markerRefs.current[silo.id]?.showCallout();
        mapViewRef.current?.animateCamera(
            {
                center: {
                    latitude: Number(lat),
                    longitude: Number(lng),
                },
                zoom: zoom,
                altitude: 10,
            },
            {
                duration: 500,
            }
        );
    });

    useEffect(() => {
        // @ts-ignore
        return navigation.addListener('tabPress', () => {
            if (navigation.isFocused()) {
                fitToElements();
            }
        });
    }, [navigation]);

    useEffect(() => {
        if (!isLoading) {
            fitToElements();
        }
    }, [isLoading]);

    function handleZoomMarker(silo: Silo, event: MarkerPressEvent) {
        mapViewRef.current?.animateCamera({
            center: {
                latitude: event.nativeEvent.coordinate.latitude,
                longitude: event.nativeEvent.coordinate.longitude,
            },
        });

        if (isLarge) {
            openSilo(silo);
        }
    }

    function openSilo(silo: Silo) {
        if (isLarge) {
            return onSelectSilo(silo.id);
        }

        return navigation.navigate('WarehouseDetails', { silo });
    }

    function getInitialPosition(): Region | undefined {
        if (Platform.OS !== 'web') return undefined;

        return {
            latitude: -16.7395102,
            longitude: -47.0015746,
            latitudeDelta: 10,
            longitudeDelta: 10,
        };
    }

    const availableLocations = data?.silos.filter((silo) => !!silo.position.latitude && !!silo.position.longitude);

    return (
        <Stack flex={1} bg='#eeeeee'>
            <MapView
                initialRegion={getInitialPosition()}
                style={{ flex: 1, backgroundColor: '#eeeeee' }}
                mapType='satellite'
                ref={mapViewRef}
                onMapLoaded={() => fitToElements()}
                provider={Platform.OS === 'ios' ? undefined : 'google'}
                // @ts-ignore required for react-native-maps-web
                googleMapsApiKey={expo.android.config.googleMaps.apiKey}
            >
                {availableLocations?.map((silo) => {
                    const { position } = silo;

                    if (!position) return null;

                    const { latitude: lat, longitude: lng } = position;

                    return (
                        <Marker
                            key={silo.id}
                            coordinate={{
                                latitude: Number(lat),
                                longitude: Number(lng),
                            }}
                            tracksViewChanges={false}
                            title={silo.name?.trim()}
                            onCalloutPress={() => openSilo(silo)}
                            onPress={(event) => handleZoomMarker(silo, event)}
                            ref={(ref) => {
                                markerRefs.current[silo.id] = ref;
                            }}
                        >
                            {Platform.OS === 'web' && (
                                <Callout>
                                    <Text fontWeight='600' color='$secondary900' mb={10} fontSize={14}>
                                        {silo.name}
                                    </Text>
                                </Callout>
                            )}
                        </Marker>
                    );
                })}
            </MapView>
        </Stack>
    );
};

export default WarehouseMaps;
