import { Query } from '@nozbe/watermelondb';
import { Q, getDatabase } from '../../config/database';
import { Raw } from './Model';
import { injectable } from 'inversify';

type TModelClass = (new (...args: unknown[]) => unknown) & {
    table: string;
};

export function Repository<TClass extends TModelClass, TModel = InstanceType<TClass>>(model: TClass) {
    const clazz = class AbstractRepository {
        protected model() {
            //@ts-ignore
            return getDatabase().get<TModel>(model.table);
        }

        /**
         * Creates a new record on database
         *
         * @param data - Data to save
         * @returns saved data
         */
        async create(data: Raw<TModel>): Promise<TModel> {
            return this.model().create((entity) => Object.assign(entity as any, data));
        }

        /**
         * Find by identification
         *
         * @param id - Identification to find
         * @returns found entity on database
         */
        async findById(id: string): Promise<TModel> {
            return this.model().find(id) as TModel;
        }

        /**
         * Find for a record on database
         *
         * @param query - Query to find
         * @returns found entities
         */
        async find(...query: Q.Clause[]): Promise<TModel[]> {
            return this.model().query(query) as Query<any>;
        }

        /**
         * Find first record from entity
         *
         * @param query - Query to find
         * @returns found entity
         */
        async findOne(...query: Q.Clause[]): Promise<TModel> {
            query.push(Q.take(1));

            return (await this.model().query(query)).at(0) as TModel;
        }

        /**
         * Count entity quantity
         *
         * @param query - Query to find
         */
        async count(...query: Q.Clause[]): Promise<number> {
            return this.model().query(query).fetchCount();
        }
    };

    return injectable()(clazz);
}
