import React from 'react';
import { Stack } from 'tamagui';
import Spinner from '../Spinner';

const LoadingScreen = () => {
    return (
        <Stack flex={1} jc='center' alignItems='center'>
            <Spinner />
        </Stack>
    );
};

export default LoadingScreen;
