import { EggsReportData } from '@/chicken-house/entities/reports/EggsReportData';
import { Report } from '@/reports/components';
import { formatBigNumber } from '@/shared/utils/number';
import React from 'react';
import { Stack } from 'tamagui';

type Props = {
    report: EggsReportData;
};

const EggReportResult = ({ report }: Props) => {
    function formatText(data: Record<'value' | 'totalBoxes', number> | undefined) {
        return `${formatBigNumber(data?.value || 0)} (${formatBigNumber(data?.totalBoxes || 0)} caixas)`;
    }

    return (
        <Stack>
            <Report.Card
                title={report.chickenHouseName}
                items={[
                    {
                        label: 'Média no período',
                        value: formatText(report.summary.averageFromPeriod),
                    },
                    {
                        label: 'Mínimo produzido no período',
                        value: formatText(report.summary.minimumFromPeriod),
                    },
                    {
                        label: 'Máximo produzido no período',
                        value: formatText(report.summary.maximumFromPeriod),
                    },
                    {
                        label: 'Soma total no período',
                        value: formatText(report.summary.sumFromPeriod),
                    },
                ]}
            />

            <Report.Chart
                averageValue={report.summary.averageFromPeriod.value}
                data={report.produced}
                maximumFromPeriod={report.summary.maximumFromPeriod}
                minimumFromPeriod={report.summary.minimumFromPeriod}
                hasBigNumbers
                stackProps={{ px: 8 }}
            />
        </Stack>
    );
};

export default EggReportResult;
