export type Coords = { x: number; y: number };

export function interpolateY(
    value: number,
    options: {
        from: { x: number; y: number };
        to: { x: number; y: number };
    }
) {
    const x = value;

    const x1 = options.from.x;
    const y1 = options.from.y;

    const x2 = options.to.x;
    const y2 = options.to.y;

    // Calcula o coeficiente angular (m)
    const m = (y2 - y1) / (x2 - x1);

    // Calcula o intercepto (b)
    const b = y1 - m * x1;

    // Calcula o valor de y para o x dado
    return m * x + b;
}

export function getPercentage(percentage: number, total: number) {
    return (percentage / 100) * total;
}
