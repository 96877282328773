import { useRecordStore } from '@/camera-video/store/useRecordStore';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { useToast } from '@/shared/hooks/useToast';
import * as MediaLibrary from 'expo-media-library';

export function useCameraRecorder(cameraUrl: string) {
    const errorHandler = useErrorHandler();
    const toast = useToast();

    const recordStore = useRecordStore(({ startRecording, stopRecording, isRecording }) => ({
        startRecording,
        stopRecording,
        isRecording,
    }));

    const startRecording = async () => {
        try {
            const permission = await MediaLibrary.requestPermissionsAsync();

            if (!permission.granted) {
                return toast.show({
                    title: 'Erro ao acessar as fotos',
                    message: 'Verifique as permissões',
                    type: 'error',
                });
            }

            await recordStore.startRecording(cameraUrl);
        } catch (err) {
            errorHandler.handle(err);
        }
    };

    const stopRecording = async () => {
        try {
            await recordStore.stopRecording();

            return toast.show({
                title: 'Gravação salva',
                message: 'Acesse a galeria para ver',
                type: 'success',
            });
        } catch (err) {
            errorHandler.handle(err);
            throw err;
        }
    };

    function toggleRecording() {
        if (!recordStore.isRecording) return startRecording();
        return stopRecording();
    }

    return {
        isRecording: recordStore.isRecording,
        startRecording,
        stopRecording,
        toggleRecording,
    };
}
