import { create } from 'zustand';
import { queryClient } from '../../../config/cache';
import { Aviary } from '../entities/Aviary';
import { Farm } from '../entities/Farm';

type SelectedFarmStore = {
    farm: Farm | null;
    aviary: Aviary | null;
    updateSelectedFarm: (farm: Farm | null, aviary: Aviary | null) => void;
};

export const useSelectedFarm = create<SelectedFarmStore>((set) => ({
    aviary: null,
    farm: null,
    updateSelectedFarm: (farm, aviary) => {
        set({ farm, aviary });

        queryClient.invalidateQueries();
    },
}));
