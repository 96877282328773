import Constants from 'expo-constants';
import React, { useMemo } from 'react';
import { Text } from 'tamagui';
import { config } from '../../../../../config/env';

type Props = {
    color?: string;
};

const AppVersion = ({ color = '#c8c8c8' }: Props) => {
    const channel = useMemo(() => {
        if (config.mode === 'production' || !config.mode) return '';

        return `-${config.mode}`;
    }, []);

    return (
        <Text color={color}>
            v{Constants.expoConfig?.version}
            {channel}
        </Text>
    );
};

export default AppVersion;
