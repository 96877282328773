import React, { ReactNode } from 'react';
import { Stack, StackProps, Text, TextProps, XStack, YStack } from 'tamagui';

type Props = {
    icon?: ReactNode;
    label: string;
    value: string | ReactNode;
    titleProps?: TextProps;
    containerProps?: StackProps;
};

const LabelAndValue = (props: Props) => {
    return (
        <XStack alignItems='center' {...props.containerProps}>
            {!!props.icon && <Stack mr={10}>{props.icon}</Stack>}

            <YStack>
                <Text color='#A0AEC0' fontSize={12} mb={4} {...props.titleProps}>
                    {props.label}
                </Text>
                <Text color='$secondary500' fontWeight='600' fontSize={16}>
                    {props.value}
                </Text>
            </YStack>
        </XStack>
    );
};

export default LabelAndValue;
