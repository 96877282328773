import React, { PropsWithChildren } from 'react';
import { Stack, StackProps, Text } from 'tamagui';

type Props = PropsWithChildren<{
    title: string;
    containerProps?: StackProps;
}>;

const SectionContainer = (props: Props) => {
    return (
        <>
            <Stack bg='#F7FAFC' p={12}>
                <Text color='#4A5568' fontWeight='600' fontSize={14}>
                    {props.title}
                </Text>
            </Stack>

            <Stack p={24} {...props.containerProps}>
                {props.children}
            </Stack>
        </>
    );
};

export default SectionContainer;
