import { useRecordStore } from '@/camera-video/store/useRecordStore';

export function useRecordStopwatch() {
    const time = useRecordStore((store) => store.timelapsed);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    return {
        minutes,
        seconds,
    };
}
