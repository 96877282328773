import { useMutation } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { useToast } from '@/shared/hooks/useToast';
import { useSiloCacheHelper } from '../helpers/useSiloCacheHelper';
import { SiloApi } from '@/warehouse/api/SiloApi';
import { useFeedTypes } from './useFeedTypes';

export type UpdateWarehouseFeedTypeParams = {
    siloId: string;
    rationId: string;
};

export function useUpdateWarehouseFeedType() {
    const errorHandler = useErrorHandler();
    const toast = useToast();

    const siloCache = useSiloCacheHelper();
    const siloApi = container.resolve(SiloApi);

    const rations = useFeedTypes();

    return useMutation({
        mutationFn: async ({ siloId, rationId }: UpdateWarehouseFeedTypeParams) => {
            await siloApi.updateSiloProperties(siloId, { ration_id: rationId });
        },

        onSuccess(_, { siloId, rationId }) {
            toast.show({
                title: 'Tipo de ração atualizado',
                message: 'Salvo com sucesso',
                type: 'success',
            });

            const findRation = rations?.data?.items?.find((ration) => ration.id === rationId);

            if (!findRation) return;

            siloCache.updateById(siloId, { ration: findRation });
        },

        onError(err) {
            errorHandler.handle(err, {
                title: 'Erro ao atualizar as opções',
                message: 'Tente novamente',
            });
        },
    });
}
