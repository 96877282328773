import * as y from 'yup';
import { InferType } from 'yup';

export const cameraScanAuthSchema = y.object({
    enableAuth: y.boolean(),
    username: y.string().when('enableAuth', ([enableAuth], schema) => (enableAuth ? schema.required() : schema)),
    password: y.string().when('enableAuth', ([enableAuth], schema) => (enableAuth ? schema.required() : schema)),
});

export type CameraScanAuth = InferType<typeof cameraScanAuthSchema>;

export const cameraNetworkConfigSchema = y
    .object({
        networkPath: y
            .string()
            .required()
            .transform((path) => path.replace(/\s/g, '')),
        auth: cameraScanAuthSchema,
    })
    .defined();

export type CameraNetworkConfig = InferType<typeof cameraNetworkConfigSchema>;
