import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import MultiSelect from '@/shared/components/ui/MultiSelect';
import { MultiSelectProps } from '@/shared/components/ui/MultiSelect/MultiSelectProps';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';

import React from 'react';

type Props = Pick<MultiSelectProps, 'onChange' | 'value'>;

const ChickenHouseSelect = (props: Props) => {
    const { data: chickenHouses } = useChickenHouses();

    return (
        <MultiSelect
            items={chickenHouses?.map((item) => ({
                label: item.name,
                caption: item.site?.name || '-',
                value: item.id,
            }))}
            icon={<FontAwesome5 name='home' />}
            label='Selecionar os aviários'
            {...props}
        />
    );
};

export default ChickenHouseSelect;
