import { useCameraScan } from '@/chicken-house/hooks/camera/useCameraScan';
import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import Spinner from '@/shared/components/ui/Spinner';
import StateMessage from '@/shared/components/ui/StateMessage';
import dayjs from 'dayjs';
import Lottie from 'lottie-react-native';
import React, { useMemo } from 'react';
import Animated, { FadeInRight, FadeOutLeft } from 'react-native-reanimated';
import { Stack, Text } from 'tamagui';
import WifiAnimation from '../../../../../assets/animations/wifi_animation.json';

type Props = {
    animationHeight?: number;
    hideCaption?: boolean;
};

const CameraScanAlert = ({ hideCaption, animationHeight }: Props) => {
    const { data: result, isError } = useCameraScan();

    const networkConfig = useCameraServer((state) => state.networkConfig);

    const caption = useMemo(() => {
        if (isError) {
            return (
                <Text color='$red10Dark' textAlign='center' fontSize={14}>
                    Erro ao escanear as câmeras. Verifique sua conexão
                </Text>
            );
        }

        if (result?.start) {
            return `Iniciado ${dayjs(result?.start).fromNow()}, ${dayjs(result?.start).format('HH:mm')}`;
        }

        return <Spinner size='small' />;
    }, [isError, result]);

    return (
        <Animated.View exiting={!hideCaption ? FadeOutLeft : undefined} entering={!hideCaption ? FadeInRight : undefined}>
            <Stack jc='center' ai='center'>
                <StateMessage
                    title='Procurando câmeras...'
                    image={
                        <Lottie
                            source={WifiAnimation}
                            autoPlay
                            loop
                            resizeMode='contain'
                            style={{
                                height: animationHeight,
                                width: 200,
                            }}
                        />
                    }
                    description='Estamos procurando as câmeras no seu IP. Isso pode demorar alguns minutos'
                    caption={
                        !hideCaption ? (
                            <Stack jc='center' ai='center'>
                                <Text color='#A0AEC0' textAlign='center' fontSize={14}>
                                    {caption}
                                </Text>

                                <Text color='$gray10' textAlign='center' fontSize={12} mt='$5'>
                                    {networkConfig?.ip}:{networkConfig?.port}
                                </Text>
                            </Stack>
                        ) : undefined
                    }
                />
            </Stack>
        </Animated.View>
    );
};

export default CameraScanAlert;
