import { createInterFont } from '@tamagui/font-inter';
import { shorthands } from '@tamagui/shorthands';
import { tokens as tamaguiTokens, themes } from '@tamagui/themes';
import { useMemo } from 'react';
import { createTamagui, createTokens } from 'tamagui';
import { useNewUIEnabled } from './hooks/useNewUIEnabled';

export function useThemeConfig() {
    const useNewUI = useNewUIEnabled();

    const tokens = createTokens({
        ...tamaguiTokens,
        color: {
            ...tamaguiTokens.color,
            primary900: useNewUI ? '#BFD6E1' : '#ffdbdb',
            primary500: useNewUI ? '#204856' : '#8D1B15',
            primary400: useNewUI ? '#1A3844' : '#52100d',
            secondary500: '#171923',
            secondary400: '#1a1a1c',
            secondary900: '#878cab',
        },
    });

    const appConfig = useMemo(
        () =>
            createTamagui({
                themes: {
                    ...themes,
                    light_primary_button: {
                        ...themes.light_red_Button,
                        background: tokens.color.primary900,
                        borderColor: tokens.color.primary900,
                    },
                    light_secondary_button: {
                        ...themes.light_blue_Button,
                        background: tokens.color.secondary500,
                        borderColorPress: '#50515c',
                        backgroundPress: '#50515c',
                        borderColor: tokens.color.secondary500,
                        color: '#ffffff',
                    },
                },
                tokens,
                shorthands,
                fonts: {
                    heading: createInterFont(),
                    body: createInterFont(),
                },
            }),
        []
    );

    return appConfig;
}

export type AppConfig = ReturnType<typeof useThemeConfig>;

declare module 'tamagui' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface TamaguiCustomConfig extends AppConfig {}
}
