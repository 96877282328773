import React, { useRef } from 'react';
import { BaseFilterProps } from './BaseFilterProps';
import { Stack } from 'tamagui';
import FilterContentFooter from './FilterContentFooter';
import Popover from '../../../Popover';

function BaseFilter(props: BaseFilterProps) {
    const popoverRef = useRef<Popover>(null);

    return (
        <Popover popoverRef={popoverRef}>
            <Popover.Trigger>{props.trigger}</Popover.Trigger>

            <Popover.Content>
                <Stack w={320} h={440}>
                    {props.children}

                    <FilterContentFooter
                        onConfirm={() => {
                            popoverRef?.current?.close();
                            props.onConfirm?.();
                        }}
                    />
                </Stack>
            </Popover.Content>
        </Popover>
    );
}

export default BaseFilter;
