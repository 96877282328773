import { create } from 'zustand';

type AquaPanelStore = {
    /**
     * Selected aqua identification
     */
    aquaId: string | null;

    /**
     * On select specific aqua
     *
     * @param aquaId - aqua identification
     */
    onSelectAqua(aquaId: string): void;
};

export const useAquaPanel = create<AquaPanelStore>((set) => ({
    aquaId: null,
    onSelectAqua(aquaId) {
        set({ aquaId });
    },
}));
