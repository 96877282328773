import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

export function usePrivacyPolicy() {
    const { navigate } = useNavigation();

    const open = useCallback(() => navigate('PrivacyPolicy'), [navigate]);

    return { open };
}
