import removeAccents from 'remove-accents';

export function useSearch<TData extends unknown[]>(
    data: TData,
    options: {
        search: string;
        transform: (data: TData[number]) => string[];
    }
) {
    return {
        results: data?.filter((item) => {
            const toSearch = removeAccents(options?.transform(item)?.join('')?.toLowerCase().trim() || '');
            const searchTerm = removeAccents(options?.search?.toLowerCase().trim() || '');

            return toSearch.includes(searchTerm);
        }) as TData,
    };
}
