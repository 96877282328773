import React from 'react';
import { G, Line } from 'react-native-svg';

type Props = {
    numberOfPanels: number;
    height: number;
    width: number;
    y: number;
};

const SiloPanels = ({ numberOfPanels, height, width: baseWidth, y }: Props) => {
    function getPanels() {
        if (!numberOfPanels || numberOfPanels <= 1) return [];

        const resultado: number[] = [0];
        const interval = height / numberOfPanels;

        for (let i = 1; i <= numberOfPanels; i++) {
            resultado.push(interval * i);
        }

        return resultado;
    }

    return (
        <G y={y}>
            {getPanels().map((val) => (
                <Line y1={val} y2={val} x1={0} x2={baseWidth} stroke='#b9a17f' strokeWidth='2' key={val} />
            ))}
        </G>
    );
};

export default SiloPanels;
