import { MenuComponentProps } from '@react-native-menu/menu';

import { Menu, MenuItem, Switch } from '@mui/material';
import React from 'react';

type Props = MenuComponentProps;

export function MenuView(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
                {props.children}
            </div>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {props.actions.map((action) => (
                    <MenuItem
                        key={action.id}
                        onClick={() => {
                            if (!action.state) {
                                handleClose();
                            }

                            props.onPressAction?.({ nativeEvent: { event: String(action.id) } });
                        }}
                        disabled={action.attributes?.disabled}
                        style={{
                            display: action.attributes?.hidden ? 'none' : undefined,
                            color: action.attributes?.destructive ? 'red' : undefined,
                            zIndex: 99999,
                        }}
                    >
                        {action.title}

                        {action.state && <Switch sx={{ alignSelf: 'flex-end', ml: 2 }} checked={action.state === 'on'} />}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
