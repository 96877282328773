import { Builder } from 'builder-pattern';
import { Model as WatermelonModel } from '@nozbe/watermelondb';

export function Model<T>() {
    return class extends WatermelonModel {
        static builder() {
            return Builder<Raw<T>>();
        }
    };
}

export type Raw<T> = Omit<T, keyof WatermelonModel>;
