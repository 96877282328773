import { DefaultTheme, ThemeProvider } from '@react-navigation/native';
import React, { PropsWithChildren } from 'react';

const NavigationTheme = (props: PropsWithChildren) => {
    return (
        <ThemeProvider
            value={{
                ...DefaultTheme,
                colors: {
                    ...DefaultTheme.colors,
                    background: '#ffffff',
                },
            }}
        >
            {props.children}
        </ThemeProvider>
    );
};

export default NavigationTheme;
