import { tableSchema } from '@nozbe/watermelondb';
import { Migration, createTable } from '@nozbe/watermelondb/Schema/migrations';
import { field, text } from '@nozbe/watermelondb/decorators';
import { Model } from 'lib';
import { Schema } from '../../../../config/database';

export class ServerConfigModel extends Model<ServerConfigModel>() {
    static table = 'server_config';

    @text('bssid')
    bssid: string;

    @text('ssid')
    ssid: string;

    @text('ip')
    ip: string;

    @field('port')
    port: number;

    static getSchema(): Schema {
        return tableSchema({
            name: 'server_config',
            columns: [
                { name: 'bssid', type: 'string', isIndexed: true },
                { name: 'ssid', type: 'string' },
                { name: 'ip', type: 'string' },
                { name: 'port', type: 'number' },
            ],
        });
    }

    static getMigrations(): Migration[] {
        return [
            {
                toVersion: 2,
                steps: [
                    createTable({
                        name: 'server_config',
                        columns: [
                            { name: 'bssid', type: 'string', isIndexed: true },
                            { name: 'ssid', type: 'string' },
                            { name: 'ip', type: 'string' },
                            { name: 'port', type: 'number' },
                        ],
                    }),
                ],
            },
        ];
    }
}
