import { BaseCard } from '@/shared/components/ui/BaseCard';
import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import { Silo } from '@/warehouse/entities/Silo';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import { useSiloPanel } from '@/warehouse/store/useSiloPanelStore';
import MaterialIcon from '@expo/vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack, Text, XStack, YStack } from 'tamagui';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';
import TitleWithSite from '../TitleWithSite';
import WarehouseStatus from '../WarehouseStatus';
import WarehouseActions from './WarehouseActions';

type Props = {
    silo: Silo;
};

const WarehouseCard = ({ silo }: Props) => {
    const navigation = useNavigation();
    const panel = useSiloPanel();

    const quantityUnits = useQuantityUnits(silo.prefer_tons);

    const largeLayout = useLargeLayout();

    function openSilo() {
        if (largeLayout) {
            return panel.onSelectSilo(silo.id);
        }

        return navigation.navigate('WarehouseDetails', { silo });
    }

    return (
        <Stack>
            <BaseCard marginBottom={20} flexDirection='row'>
                <TouchableOpacity onPress={openSilo} activeOpacity={0.6} style={{ flex: 1 }} accessibilityLabel={silo.name}>
                    <TitleWithSite mb={18} site={silo.site}>
                        {silo.name}
                    </TitleWithSite>

                    <Stack mb={10}>
                        <WarehouseStatus
                            deviceStatus={silo.device_status || 'DISCONNECTED'}
                            levelStatus={silo.level.status || 'ALERT'}
                        />
                    </Stack>

                    <XStack>
                        <LabelAndValue
                            icon={<MaterialIcon name='calendar' size={25} color='#A0AEC0' />}
                            label='Dias Restantes'
                            value={
                                <>
                                    {silo.level.remaining.days ?? 0} {pluralize('dia', silo.level.remaining.days)}{' '}
                                    {pluralize('restante', silo.level.remaining.days)}
                                </>
                            }
                            containerProps={{ flexGrow: 1 }}
                        />

                        <LabelAndValue
                            icon={<MaterialIcon name='unfold-more-horizontal' size={25} color='#A0AEC0' />}
                            label='Nível'
                            value={quantityUnits.format({
                                perc: silo.level.current.percentage,
                                tons: silo.level.current.tons,
                            })}
                            containerProps={{ flexGrow: 1, minWidth: 40, mb: 13 }}
                        />
                    </XStack>

                    <YStack mt={24}>
                        <Text color='#A0AEC0' fontSize={12} mb={4}>
                            Última atualização:{' '}
                            <Text color='$secondary500' fontWeight='600'>
                                {dayjs(silo.last_seen).fromNow()}
                            </Text>
                        </Text>
                    </YStack>
                </TouchableOpacity>

                <WarehouseActions
                    silo={silo}
                    stackProps={{
                        backgroundColor: 'transparent',
                        margin: -12,
                    }}
                    context='list'
                />
            </BaseCard>
        </Stack>
    );
};

export default WarehouseCard;
