import React from 'react';
import { VictoryAxis } from 'victory-native';

export const senseChartAxis = {
    x: (
        <VictoryAxis
            style={{
                tickLabels: { angle: -45 },
            }}
            crossAxis
        />
    ),
    y: <VictoryAxis dependentAxis />,
};
