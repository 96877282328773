import { useCallback, useState } from 'react';
import { FilterProps } from '../components/ui/Filters/FilterProps';

type FilterType = Partial<Record<PropertyKey, string[]>>;

export function useFilter<T extends FilterType>() {
    const [filter, setFilter] = useState<T>({} as T);

    const updateFilter = useCallback(<Key extends keyof T>(key: Key, value: T[Key]) => {
        return setFilter((oldFilter) => ({
            ...oldFilter,
            [key]: value,
        }));
    }, []);

    const getFilterProps = useCallback(
        <Key extends keyof T>(key: Key): Pick<FilterProps, 'onConfirm' | 'value'> => ({
            onConfirm: (value) => updateFilter(key, value as T[Key]),
            value: filter[key],
        }),
        [filter, updateFilter]
    );

    const hasFilter = useCallback(
        <Key extends keyof T>(key?: Key) => {
            if (key) {
                return (filter[key]?.length || 0) > 0;
            }

            return Object.keys(filter).some((k) => {
                return (filter[k]?.length || 0) > 0;
            });
        },
        [filter]
    );

    return {
        filter,
        hasFilter,
        updateFilter,
        getFilterProps,
    };
}

export type UseFilter<T extends FilterType> = ReturnType<typeof useFilter<T>>;
