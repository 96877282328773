import AppVersion from '@/shared/components/ui/AppVersion';
import React from 'react';
import { Stack } from 'tamagui';

const DrawerFooter = () => {
    return (
        <Stack jc='center' ai='center' mt={12}>
            <AppVersion color='#323e41' />
        </Stack>
    );
};

export default DrawerFooter;
