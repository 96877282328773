import SelectSheet, { SelectSheetProps } from '@/shared/components/ui/SelectSheet';
import { Silo } from '@/warehouse/entities/Silo';
import { useFeedTypes } from '@/warehouse/hooks/feed/useFeedTypes';
import { useUpdateWarehouseFeedType } from '@/warehouse/hooks/feed/useUpdateWarehouseFeedType';
import React from 'react';

type Props = Pick<SelectSheetProps, 'isOpen' | 'onClose'> & {
    silo: Pick<Silo, 'id' | 'ration'>;
};

const UpdateFeedTypeSheet = ({ silo, ...props }: Props) => {
    const { data: feedTypes } = useFeedTypes();

    const updateWarehouseFeedType = useUpdateWarehouseFeedType();

    async function updateFeedType(rationId: string) {
        updateWarehouseFeedType.mutateAsync({
            siloId: silo.id,
            rationId,
        });
    }

    return (
        <SelectSheet
            title='Alterar tipo de ração'
            description='Selecione o tipo de ração que o silo contém:'
            value={silo.ration?.id}
            items={
                feedTypes?.items.map(({ name: nome, id }) => ({
                    label: nome,
                    value: id,
                })) || []
            }
            onConfirm={updateFeedType}
            {...props}
        />
    );
};

export default UpdateFeedTypeSheet;
