import { createDrawerNavigator } from '@react-navigation/drawer';
import CustomAppDrawer from './components/CustomAppDrawer';
import { useMainRoutes } from './hooks/useMainRoutes';
import { MainRoutesParams } from './types';
import { useLargeLayout } from '../../../hooks/useLargeLayout';
import { useTheme } from 'tamagui';

const Drawer = createDrawerNavigator<MainRoutesParams>();

const DrawerContentRenderer = () => <CustomAppDrawer />;

export const MainDrawer = () => {
    const sections = useMainRoutes();
    const isLargeScreen = useLargeLayout();

    const theme = useTheme();

    return (
        <Drawer.Navigator
            initialRouteName='Places'
            drawerContent={DrawerContentRenderer}
            screenOptions={{
                headerTintColor: '#ffffff',
                headerStyle: {
                    backgroundColor: theme.primary500.get(),
                },
                headerShadowVisible: false,
                drawerType: isLargeScreen ? 'permanent' : 'front',
                drawerStyle: {
                    borderRightColor: 'transparent',
                    borderRightWidth: 0,
                },
                headerLeft: isLargeScreen ? () => null : undefined,
            }}
            defaultStatus={isLargeScreen ? 'open' : 'closed'}
        >
            {sections.map((section) =>
                section.routes.map((route) => (
                    <Drawer.Screen
                        key={route.key}
                        name={route.key}
                        component={route.component}
                        options={{ title: route.title, drawerIcon: route.icon }}
                    />
                ))
            )}
        </Drawer.Navigator>
    );
};
