import { BaseCard } from '@/shared/components/ui/BaseCard';
import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import { Ration } from '@/warehouse/api/types/FeedResponse';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import * as Haptics from 'expo-haptics';
import React from 'react';
import { ScaleDecorator } from 'react-native-draggable-flatlist';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { Stack, Text, XStack } from 'tamagui';
import FeedMenu from './FeedMenu';

type Props = {
    feed: Ration;
    isActive: boolean;
    getIndex?: () => number | undefined;
    onDrag?: () => void;
};

const FeedCard = ({ feed, onDrag, getIndex, isActive }: Props) => {
    return (
        <ScaleDecorator activeScale={1.05}>
            <BaseCard
                mb={12}
                bg={isActive ? '#f4f4f4' : 'white'}
                pl={12}
                flexDirection='row'
                pressStyle={{ backgroundColor: '#f4f4f4' }}
            >
                <TouchableWithoutFeedback
                    onLongPress={() => {
                        onDrag?.();
                        Haptics.selectionAsync();
                    }}
                    delayLongPress={100}
                >
                    <XStack ai='center'>
                        <MaterialCommunityIcons name='drag' size={22} color='#A0AEC0' />

                        <Text color='#A0AEC0' fontSize={12} w={25}>
                            P{(getIndex?.() || 0) + 1}
                        </Text>

                        <LabelAndValue label='Nome' value={feed.name || '-'} containerProps={{ width: 160, ml: 10 }} />

                        <LabelAndValue label='Densidade' value={Number(feed.density).toFixed(2)} />
                    </XStack>
                </TouchableWithoutFeedback>

                <Stack flex={1} jc='center' ai='flex-end'>
                    <FeedMenu feed={feed} />
                </Stack>
            </BaseCard>
        </ScaleDecorator>
    );
};

export default FeedCard;
