import { useAquaEnabled } from '@/aqua/hooks/flags/useAquaEnabled';
import Aqua from '@/aqua/screens/Aqua';
import { AquaManager } from '@/aqua/screens/AquaManager';
import PlaceSetupIcon from '@/chicken-house/components/PlaceSetupIcon';
import PlaceEggs from '@/chicken-house/screens/PlaceEggs';
import { useSenseEnabled } from '@/sense/hooks/flags/useSenseEnabled';
import Sense from '@/sense/screens/Sense';
import { SenseManager } from '@/sense/screens/SenseManager';
import AppLogo from '@/shared/components/ui/AppLogo';
import { WarehouseIcon } from '@/shared/components/ui/Icons/WarehouseIcon';
import SelectFarmButton from '@/user/components/SelectFarmButton';
import Profile from '@/user/screens/Profile';
import WarehouseManager from '@/warehouse/screens/WarehouseManager';
import WarehouseTabs from '@/warehouse/screens/WarehousesTabs';
import { FontAwesome5 } from '@expo/vector-icons';
import Entypo from '@expo/vector-icons/Entypo';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useMemo } from 'react';
import { Stack, useTheme } from 'tamagui';
import { useLargeLayout } from '../../hooks/useLargeLayout';
import { useHomeTabsOptions } from './hooks/useHomeTabsOptions';
import { MainRoutesParams } from './Main/types';

const BottomTabs = createBottomTabNavigator<MainRoutesParams>();
const TopTabs = createMaterialTopTabNavigator<MainRoutesParams>();

export const HomeTabs = () => {
    const theme = useTheme();

    const isAquaEnabled = useAquaEnabled();
    const isSenseEnabled = useSenseEnabled();

    const isLarge = useLargeLayout();

    const tabOptions = useHomeTabsOptions();

    const Tabs = useMemo(() => (isLarge ? TopTabs : BottomTabs), [isLarge]);

    return (
        // @ts-ignore
        <Tabs.Navigator
            screenOptions={() => ({
                tabBarActiveTintColor: theme.primary500.get() as string,
                tabBarHideOnKeyboard: true,
                headerTitle: '',
                headerLeft: () => <AppLogo />,
                headerRight: () => <SelectFarmButton />,
                headerRightContainerStyle: {
                    paddingRight: 20,
                },
                headerLeftContainerStyle: {
                    paddingLeft: 10,
                },
                headerStyle: {
                    height: 100,
                },
                headerTitleAlign: 'center',
                ...tabOptions,
            })}
            sceneContainerStyle={{
                paddingHorizontal: isLarge ? 24 : 0,
            }}
            initialRouteName='Places'
        >
            <Tabs.Screen
                name='Places'
                component={PlaceEggs}
                options={{
                    title: 'Aviários',
                    tabBarIcon: ({ color }) => <FontAwesome5 size={18} color={color} name='home' />,
                    headerStyle: {
                        backgroundColor: theme.secondary500.get() as string,
                        borderWidth: 0,
                        height: 98,
                    },
                    headerRight: () => (
                        <Stack flexDirection='row' ai='center'>
                            <SelectFarmButton />
                        </Stack>
                    ),
                    headerLeft: () => (
                        <Stack flexDirection='row'>
                            <PlaceSetupIcon color='light' />
                            <AppLogo mode='white' />
                        </Stack>
                    ),
                    headerShadowVisible: false,
                }}
            />

            <Tabs.Screen
                name='Warehouses'
                component={isLarge ? WarehouseManager : WarehouseTabs}
                options={{
                    title: 'Silos',
                    tabBarIcon: ({ color }) => <WarehouseIcon size={22} color={color} />,
                    headerShadowVisible: false,
                }}
            />

            {isAquaEnabled && (
                <Tabs.Screen
                    name='Aqua'
                    component={isLarge ? AquaManager : Aqua}
                    options={{
                        title: 'Água',
                        tabBarIcon: ({ color }) => <Entypo name='water' size={22} color={color} />,
                    }}
                />
            )}

            {isSenseEnabled && (
                <Tabs.Screen
                    name='Sense'
                    component={isLarge ? SenseManager : Sense}
                    options={{
                        title: 'Ambiência',
                        tabBarIcon: ({ color }) => <FontAwesome5 name='image' size={22} color={color} />,
                    }}
                />
            )}

            {!isLarge && (
                <Tabs.Screen
                    name='Profile'
                    component={Profile}
                    options={{
                        title: 'Menu',
                        tabBarIcon: ({ color }) => <FontAwesome5 name='bars' size={18} color={color} />,
                        headerShadowVisible: false,
                        headerTitleAlign: 'left',
                    }}
                />
            )}
        </Tabs.Navigator>
    );
};
