import { CameraSource } from '@/camera-video/entities/CameraSource';
import { PlaceSetupForm } from '@/chicken-house/entities/PlaceSetupForm';
import MaterialIcon from '@expo/vector-icons/MaterialCommunityIcons';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from 'react-native';
import { Stack, Text } from 'tamagui';

type Props = {
    index: number;
    source: CameraSource;
};

const EditCameraName = (props: Props) => {
    const form = useFormContext<PlaceSetupForm>();

    return (
        <Stack paddingVertical='$2'>
            <Stack flexDirection='row' alignItems='center' borderBottomColor='#cacaca' borderBottomWidth={1}>
                <Stack mr={6}>
                    <MaterialIcon name='pencil' size={16} color='#000000' />
                </Stack>

                <TextInput
                    numberOfLines={1}
                    defaultValue=''
                    onChangeText={(value) => {
                        form.setValue(`places.${props.index}`, {
                            name: value,
                            source: props.source,
                        });
                    }}
                    style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        flex: 1,
                        color: '#000000',
                    }}
                    placeholder='Aviário sem nome'
                    removeClippedSubviews={false}
                    maxLength={50}
                />
            </Stack>

            <Text mt={4} fontSize={10} color='#000000' selectable>
                {props.source.url}
            </Text>
        </Stack>
    );
};

export default EditCameraName;
