import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../store/AuthStore';
import { LoginFormData } from '../utils/validators/loginSchema';

export function useLogin() {
    const auth = useAuth();

    return useMutation({
        mutationFn: async (loginForm: LoginFormData) => {
            return auth.onAuth(loginForm);
        },
    });
}
