import { useSilos } from './useSilos';

export function useSiloById(id: string | null) {
    const { data, ...silos } = useSilos({
        refetchOnMount: false,
    });

    return {
        ...silos,
        data: data?.silos?.find((s) => String(s.id) === String(id)),
    };
}
