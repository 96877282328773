import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import { DEFAULT_WIFI_PREFIX } from '@/chicken-house/hooks/wifi/useCheckHasWifi';
import { useOpenWifiSettings } from '@/chicken-house/hooks/wifi/useOpenWifiSettings';
import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import Button from '@/shared/components/ui/Button';
import StateMessage from '@/shared/components/ui/StateMessage';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { Feather } from '@expo/vector-icons';
import React, { useEffect } from 'react';
import { Stack, Text, useTheme } from 'tamagui';
import { StepAlertProps } from './StepAlertProps';
import WifiName from './WifiName';
import { useNavigation } from '@react-navigation/native';

const WifiNotValidAlert = ({ animationHeight, isSimple }: StepAlertProps) => {
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const navigation = useNavigation();

    const { goToWifiSettings } = useOpenWifiSettings();

    const chickenHouses = useChickenHouses();

    const isUsingRemoteServer = useCameraServer((store) => store.networkConfig?.shouldUseRemoteServer);
    const changeServerMode = useCameraServer((store) => store.setServerMode);

    useEffect(() => {
        if (chickenHouses.error) {
            errorHandler.handle(chickenHouses.error, {
                title: 'Ocorreu um erro ao conectar ao servidor remoto das câmeras',
                message: 'Por favor, verifique sua conexão',
            });
        }
    }, [chickenHouses.error]);

    function handleRemotePress() {
        navigation.navigate('Places');

        changeServerMode('remote');
    }

    return (
        <Stack flex={1} jc='center'>
            <StateMessage
                image={
                    isSimple ? undefined : (
                        <Stack mb={16}>
                            <Feather
                                name='alert-triangle'
                                size={(animationHeight || 0) / 5}
                                color={theme.secondary500.get() as string}
                            />
                        </Stack>
                    )
                }
                title={
                    <Stack flexDirection='row' ai='center' mb={12}>
                        {isSimple ? <Feather name='globe' size={18} color={theme.secondary500.get() as string} /> : null}

                        <Text color='$secondary500' fontWeight='600' fontSize={18} ml={12}>
                            Rede WIFI Inválida
                        </Text>
                    </Stack>
                }
                description={
                    <Text>
                        Não foi possível escanear as câmeras da rede pois o prefixo{' '}
                        <Text color='$secondary500' fontWeight='600' fontSize={12}>
                            {DEFAULT_WIFI_PREFIX}
                        </Text>{' '}
                        não foi encontrado no SSID da sua rede. Conecte-se a uma rede válida e tente novamente.
                    </Text>
                }
                caption={
                    <Stack alignItems='center' jc='center'>
                        <WifiName mb={32} />

                        <Button
                            color='secondary'
                            onPress={() => goToWifiSettings()}
                            width='100%'
                            icon={<Feather name='settings' size={14} />}
                        >
                            Abrir configurações do wifi
                        </Button>

                        <Button
                            onPress={() => handleRemotePress()}
                            mt={12}
                            icon={<Feather name='globe' size={14} />}
                            width='100%'
                            loading={chickenHouses.isLoading && isUsingRemoteServer}
                        >
                            Usar servidor remoto
                        </Button>
                    </Stack>
                }
            />
        </Stack>
    );
};

export default WifiNotValidAlert;
