import { PropsWithChildren, useCallback } from 'react';
import * as Sentry from '@sentry/react-native';

export function ErrorHandlerProvider({ children }: PropsWithChildren) {
    const errorHandler = useCallback((err: unknown) => {
        Sentry.captureException(err);
    }, []);

    return (
        <Sentry.ErrorBoundary onError={errorHandler}>
            <Sentry.TouchEventBoundary>{children}</Sentry.TouchEventBoundary>
        </Sentry.ErrorBoundary>
    );
}
