import { useEffect, useRef } from 'react';
import { AppState, NativeEventSubscription } from 'react-native';
import { useSilos } from './silos/useSilos';

export function useSilosRefetch() {
    const listener = useRef<NativeEventSubscription>();

    const { refetch } = useSilos();

    useEffect(() => {
        listener.current = AppState.addEventListener('change', (state) => {
            if (state === 'active') {
                return refetch();
            }
        });

        return () => listener.current?.remove();
    }, []);
}
