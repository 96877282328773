import { BaseCard } from '@/shared/components/ui/BaseCard';
import { Platform } from 'react-native';
import { Text, styled } from 'tamagui';

export const InfoCard = styled(BaseCard, {
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start',
    marginRight: 12,
    '$platform-web': {
        flex: 1,
    },
    '$platform-native': {
        p: 0,
        borderWidth: 0,
        pr: 16,
        pl: 24,
    },
});

export const CardTitle = styled(Text, {
    color: '$secondary500',
    textAlign: 'left',
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 18,
});

export const InfoTitle = styled(Text, {
    color: '$secondary900',
    textAlign: 'left',
    fontSize: 12,
});

export const InfoContent = styled(Text, {
    color: '$secondary500',
    fontWeight: '700',
    textAlign: 'left',
    fontSize: 18,
    marginTop: Platform.select({ ios: 8, android: 3 }),
});
