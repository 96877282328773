import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { ScrollView, Stack } from 'tamagui';
import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';
import DrawerRoutes from './DrawerRoutes';
import DrawerSeparator from './DrawerSeparator';

const CustomAppDrawer = () => {
    return (
        <Stack flex={1}>
            <LinearGradient colors={['#1E4651', '#000000']} style={{ flex: 1 }}>
                <DrawerHeader />

                <Stack flex={1}>
                    <DrawerSeparator mt={24} />

                    <ScrollView contentContainerStyle={{ marginTop: 12 }} style={{ flex: 1 }}>
                        <DrawerRoutes />

                        <DrawerFooter />
                    </ScrollView>
                </Stack>
            </LinearGradient>
        </Stack>
    );
};

export default CustomAppDrawer;
