import { useWindowDimensions } from 'react-native';
import { useLargeLayout } from '../../../../../hooks/useLargeLayout';
import { usePanel } from '../Panel/hooks/usePanel';
import { useNewUIEnabled } from '../../../../../config/theme/hooks/useNewUIEnabled';

export function useChartWidth(defaultWidth = 300) {
    const isLargeScreen = useLargeLayout();
    const dimensions = useWindowDimensions();
    const { isPanel } = usePanel();

    const isNewUI = useNewUIEnabled();

    function getWidth() {
        const SIDEBAR_WIDTH = isNewUI ? 320 : 0;

        const utilScreenWidth = dimensions.width - SIDEBAR_WIDTH;

        if (isLargeScreen) return utilScreenWidth / (isPanel ? 2 : 1);

        if (!defaultWidth) return defaultWidth;

        if (defaultWidth < utilScreenWidth) {
            return utilScreenWidth;
        }

        return defaultWidth;
    }

    return getWidth();
}
