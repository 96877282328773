import StateMessage from '@/shared/components/ui/StateMessage';
import { Feather } from '@expo/vector-icons';
import React from 'react';
import { Stack, useTheme } from 'tamagui';
import { StepAlertProps } from './StepAlertProps';

const NotConnectedAlert = ({ animationHeight }: StepAlertProps) => {
    const theme = useTheme();

    return (
        <Stack flex={1} jc='center'>
            <StateMessage
                image={
                    <Stack mb={16}>
                        <Feather name='wifi-off' size={(animationHeight || 0) / 2} color={theme.red9Dark.get() as string} />
                    </Stack>
                }
                title='Sem conexão'
                description='Você precisa de uma conexão para ver os núcleos e aviários. Conecte-se a uma rede e tente novamente.'
            />
        </Stack>
    );
};

export default NotConnectedAlert;
