import Spinner from '@/shared/components/ui/Spinner';
import StateMessage from '@/shared/components/ui/StateMessage';
import Lottie from 'lottie-react-native';
import React from 'react';
import Animated, { FadeOutLeft } from 'react-native-reanimated';
import { Stack } from 'tamagui';
import NetworkAnimation from '../../../../../assets/animations/network_animation.json';
import { StepAlertProps } from './StepAlertProps';

const DnsScanAlert = (props: StepAlertProps) => {
    return (
        <Animated.View exiting={FadeOutLeft}>
            <Stack jc='center' ai='center'>
                <StateMessage
                    title='Procurando DNS...'
                    image={
                        <Lottie
                            source={NetworkAnimation}
                            autoPlay
                            loop
                            resizeMode='contain'
                            style={{
                                height: props.animationHeight,
                                width: 500,
                            }}
                        />
                    }
                    description='Estamos procurando os DNS na sua rede para conectar-se as câmeras'
                    caption={props.hideCaption ? null : <Spinner size='small' />}
                />
            </Stack>
        </Animated.View>
    );
};

export default DnsScanAlert;
