import React from 'react';
import { Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export const SafeBottom = () => {
    const { bottom } = useSafeAreaInsets();

    const extraSpace = Platform.OS === 'android' ? 16 : 0;

    return <View style={{ height: bottom ? bottom + extraSpace : 16 }} />;
};
