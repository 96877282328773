import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import React, { useState } from 'react';
import Spinner from '../Spinner';
import { FABProps } from './FABProps';

export const FAB = ({ icon, onPress, label, isLoading, actions, onActionPress }: FABProps) => {
    const [open, setOpen] = useState(false);

    return (
        <SpeedDial
            ariaLabel={label}
            onClick={onPress}
            sx={{ position: 'absolute', right: 22, bottom: 22, mb: 2 }}
            color='primary'
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open && !isLoading}
            icon={isLoading ? <Spinner size='small' color='#ffffff' /> : <MaterialIcons name={icon} color='#ffffff' size={26} />}
        >
            {actions?.map((action) => (
                <SpeedDialAction
                    key={action.key}
                    icon={action.icon}
                    tooltipTitle={action.label}
                    onClick={() => onActionPress?.(action)}
                    tooltipOpen
                />
            ))}
        </SpeedDial>
    );
};
