import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { container } from '../../../config/di';
import { AuthApi } from '../api/AuthApi';
import { Auth } from '../entities/Auth';
import { LoginFormData } from '../utils/validators/loginSchema';
import { createAuthStorage } from './adapters/auth-mmkv-storage-adapter';
import { useUser } from './UserStore';

type AuthStore = {
    /**
     * Authenticated user
     */
    auth: Auth | null;

    /**
     * Handle save authentification result on store
     *
     * @param auth - Authentication result
     */
    onAuth(credentials: LoginFormData): Promise<Auth>;

    /**
     * Logout user
     */
    logout(): Promise<void>;
};

export const useAuth = create(
    persist<AuthStore>(
        (set) => ({
            auth: null,
            onAuth: async ({ email, password }: LoginFormData) => {
                const authApi = container.resolve(AuthApi);

                const authResponse = await authApi.authenticate(email, password);

                const auth: Auth = {
                    token: authResponse.token,
                };

                set({ auth });

                return authResponse;
            },
            logout: async () => {
                set({ auth: null });
                useUser.setState({ user: null });
            },
        }),
        {
            name: 'alltis.auth-store',
            storage: createJSONStorage(() => createAuthStorage()),
            version: 1,
        }
    )
);
