import React, { PropsWithChildren } from 'react';
import { Stack, styled } from 'tamagui';

export const ContainerStack = styled(Stack, {
    height: '100%',
    width: '100%',
    backgroundColor: '$secondary500',
    jc: 'center',
    ai: 'center',
    overflow: 'hidden',
    borderRadius: 4,
});

type Props = PropsWithChildren<{
    index: number;
}>;

const CameraContainer = (props: Props) => {
    return <ContainerStack>{props.children}</ContainerStack>;
};

export default CameraContainer;
