import SiteName from '@/warehouse/components/SiteName';
import { SiloLocation, SiloSite } from '@/warehouse/entities/Silo';
import React, { PropsWithChildren } from 'react';
import { Stack, StackProps, Text } from 'tamagui';

type Props = PropsWithChildren<{
    site?: Partial<SiloSite>;
    location?: Partial<SiloLocation>;
}> &
    Pick<StackProps, 'mb' | 'mt'>;

const TitleWithSite = (props: Props) => {
    return (
        <Stack alignSelf='flex-start'>
            <SiteName site={props.site} stackProps={{ mb: 10 }} />

            <Text color='$secondary500' fontWeight='600' fontSize={22} numberOfLines={3} {...props}>
                {props.children}
            </Text>
        </Stack>
    );
};

export default TitleWithSite;
