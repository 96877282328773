import { useEffect } from 'react';
import { useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';

export function useRecordAnimation() {
    const opacity = useSharedValue(0);

    useEffect(() => {
        opacity.value = withRepeat(withTiming(1, { duration: 500 }), Infinity, true);
    }, [opacity]);

    return {
        opacity,
    };
}
