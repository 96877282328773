import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import { createURL } from '@/chicken-house/utils/network';
import axios from 'axios';

export const cameraScanApi = axios.create();

cameraScanApi.interceptors.request.use((config) => {
    const { networkConfig } = useCameraServer.getState();

    if (networkConfig?.ip && networkConfig.port) {
        config.baseURL = createURL({
            protocol: 'http',
            host: networkConfig?.ip,
            port: networkConfig?.port,
        });
    }

    return config;
}, Promise.reject);
