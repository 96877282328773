import BottomSheet, { BottomSheetProps } from '@gorhom/bottom-sheet';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Stack } from 'tamagui';

export type SheetProps = BottomSheetProps;

const Sheet = forwardRef<BottomSheet, SheetProps>((props, ref) => {
    const [isOpen, setIsOpen] = useState((props.index || 0) >= 0);

    function onClose() {
        setIsOpen(false);
        props.onClose?.();
    }

    function onOpen() {
        setIsOpen(true);
        props.onChange?.(1);
    }

    useImperativeHandle(ref, () => ({
        close: onClose,
        collapse: onClose,
        forceClose: onClose,
        expand: onOpen,
        snapToIndex: onOpen,
        snapToPosition: onOpen,
    }));

    useEffect(() => setIsOpen((props.index || 0) >= 0), [props.index]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                props.onClose?.();
                props.onChange?.(-1);
            }}
        >
            <DialogContent
                sx={{
                    width: 400,
                }}
            >
                <Stack bg='white'>
                    <Stack>{props.children}</Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                {/* @ts-ignore */}
                {props.footerComponent?.()}
            </DialogActions>
        </Dialog>
    );
});

Sheet.displayName = 'Sheet';

export type Sheet = BottomSheet;

export { Sheet };
