import { AquaConsumption } from '@/aqua/entities/AquaConsumption';
import { useChartWidth } from '@/shared/components/ui/ScrollableChart/useChartWidth';
import dayjs from 'dayjs';
import { sort } from 'fast-sort';
import React from 'react';
import { Dimensions } from 'react-native';
import { LineChart } from 'react-native-chart-kit';

type Props = {
    consumption: AquaConsumption;
};

const AquaConsumptionSimpleChart = ({ consumption: aquaConsumption }: Props) => {
    const chartWidth = useChartWidth(Dimensions.get('screen').width + 55);

    const consumption = sort(aquaConsumption.historic || []).asc((c) => dayjs(c.timestamp).toDate());

    const aquaData = consumption.map((c) => Math.ceil(c.value));

    if (!aquaData.length) return null;

    return (
        <LineChart
            data={{
                labels: [],
                datasets: [
                    {
                        data: aquaData,
                        color: (opacity = 1) => `rgba(66, 153, 225, ${opacity})`,
                    },
                ],
            }}
            chartConfig={{
                backgroundGradientFrom: '#ffffff',
                backgroundGradientTo: '#ffffff',
                color: (opacity = 1) => `rgba(0, 153, 225, ${opacity})`,
                labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                decimalPlaces: 0,
            }}
            height={80}
            style={{ marginLeft: -100 }}
            width={chartWidth || 200}
            bezier
            withHorizontalLabels={false}
            withVerticalLabels={false}
            withDots={false}
            transparent
        />
    );
};

export default AquaConsumptionSimpleChart;
