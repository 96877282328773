import { useSyncLoggedUser } from '@/authentication/hooks/useSyncLoggedUser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFontConfig } from '../config/theme/hooks/useFontConfig';
import { useNativeUpdates } from './useNativeUpdate';
import { useUpdates } from './useUpdates';

export function useInitialAppLoading() {
    const { isLoading: isLoadingFonts } = useFontConfig();
    const { checkForUpdates, isLoading: isUpdating } = useUpdates();
    const { checkForBundleUpdate } = useNativeUpdates();

    useSyncLoggedUser();

    useEffect(() => {
        checkForUpdates().then(() => checkForBundleUpdate());
    }, []);

    const [isNavigationReady, setIsNavigationReady] = useState(false);

    const onNavigationReady = useCallback(() => setIsNavigationReady(true), []);

    const isLoading = useMemo(() => {
        return isLoadingFonts || isUpdating || !isNavigationReady;
    }, [isLoadingFonts || isUpdating || !isNavigationReady]);

    return { onNavigationReady, isLoading };
}
