export const timestampToDate = (value: number | undefined) => {
    if (!value) return undefined;

    let timestampString = String(value);

    if (timestampString.length > 13) {
        timestampString = timestampString.slice(0, -6);
    }

    return new Date(Number(timestampString));
};
