import { useCallback } from 'react';
import * as Burnt from 'burnt';

type ToastOptions = {
    title: string;
    message: string;
    type?: 'success' | 'error';
};

export function useToast() {
    const show = useCallback((options: ToastOptions) => {
        return Burnt.toast({
            from: 'bottom',
            haptic: options.type || 'success',
            preset: options.type === 'error' ? 'error' : 'done',
            title: options.title,
            message: options.message,
            duration: 1.5,
        });
    }, []);

    return {
        show,
    };
}
