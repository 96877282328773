import { DrawerActions, getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { Stack } from 'tamagui';
import { useMainRoutes } from '../../../hooks/useMainRoutes';
import { DrawerRoute } from '../../../types';
import DrawerItem from '../DrawerItem';
import DrawerSeparator from '../DrawerSeparator';

const DrawerRoutes = () => {
    const sections = useMainRoutes();

    const route = useRoute();

    const focusedRouteName = useMemo(() => getFocusedRouteNameFromRoute(route), [route]);

    const navigation = useNavigation();

    const onPress = useCallback((item: DrawerRoute) => {
        navigation.navigate(item.key);
        navigation.dispatch(DrawerActions.closeDrawer());
    }, []);

    const isTabActive = useCallback((item: DrawerRoute) => focusedRouteName === item.key, [focusedRouteName]);

    return (
        <>
            {sections.map((section, index) => (
                <Stack key={index}>
                    {section.routes.map((route) =>
                        route.hide ? null : (
                            <DrawerItem key={route.key} item={route} onPress={onPress} isActive={isTabActive(route)} />
                        )
                    )}

                    <DrawerSeparator my={12} />
                </Stack>
            ))}
        </>
    );
};

export default DrawerRoutes;
