import { NetInfoStateType, useNetInfo } from '@react-native-community/netinfo';
import { useLogger } from '../../../../config/logging/useLogger';

export const DEFAULT_WIFI_PREFIX = 'ALLTIS_EGG';

type InvalidNetworkReason = 'invalid_ssid' | 'no_connection';

type UseCheckHasWifiReturn =
    | {
          isValid: true;
      }
    | {
          isValid: false;
          reason: InvalidNetworkReason;
      };

export function useCheckHasWifi(): UseCheckHasWifiReturn {
    const netInfo = useNetInfo();

    if (netInfo.type === NetInfoStateType.none) {
        return {
            isValid: false,
            reason: 'no_connection',
        };
    }

    if (netInfo.type === NetInfoStateType.wifi) {
        const isValid = netInfo?.details?.ssid?.startsWith(DEFAULT_WIFI_PREFIX) || false;

        if (isValid) return { isValid };
    }

    return {
        isValid: false,
        reason: 'invalid_ssid',
    };
}
