import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { container } from '../../../config/di';
import { Logger } from '../../../config/logging';
import { AuthApi } from '../api/AuthApi';
import { User } from '../entities/User';
import { createAuthStorage } from './adapters/auth-mmkv-storage-adapter';

type UserStore = {
    /**
     * Current logged user
     */
    user: User | null;

    /**
     * Update current user
     *
     * @returns - Logged user
     */
    sync(): Promise<User>;
};

export const useUser = create(
    persist<UserStore>(
        (set) => ({
            user: null,

            async sync() {
                const authApi = container.resolve(AuthApi);
                const user = await authApi.getCurrentUser();

                Logger.setUser({ email: user?.email });

                set({ user });

                return user;
            },
        }),
        {
            name: 'alltis.user-store',
            storage: createJSONStorage(() => createAuthStorage()),
            version: 1,
        }
    )
);
