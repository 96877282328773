import { injectable } from 'inversify';
import { Repository } from 'lib';
import { ServerConfigModel } from './ServerConfigModel';
import { Q } from '@nozbe/watermelondb';

@injectable()
export class ServerConfigRepository extends Repository(ServerConfigModel) {
    async findByBSSID(bssid: string): Promise<ServerConfigModel | undefined> {
        return (await this.find(Q.where('bssid', Q.eq(bssid)), Q.take(1)))[0];
    }
}
