import { Image } from 'expo-image';
import React, { memo } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Avatar, Stack } from 'tamagui';

// @ts-ignore
import WhiteLogo from '../../../../../../assets/logo/alltis_logo_white.png';

// @ts-ignore
import DefaultAvatar from '../../../../../../assets/images/default-avatar.jpg';

import { useDrawerConstants } from '../../../hooks/useDrawerConstants';

const DrawerHeader = () => {
    const topInsets = useSafeAreaInsets();
    const { paddingX } = useDrawerConstants();

    return (
        <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            mt={(topInsets.top || 24) + 8}
            px={paddingX}
        >
            <Avatar circular size='$2' borderColor='white' borderWidth={1}>
                <Avatar.Image src={DefaultAvatar} width='100%' height='100%' />

                <Avatar.Fallback backgroundColor='#000000' />
            </Avatar>

            <Image source={WhiteLogo} style={{ width: 120, height: 30 }} contentFit='contain' />

            <Stack width={30} />
        </Stack>
    );
};

export default memo(DrawerHeader);
