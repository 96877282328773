import { G, Path, Svg } from 'react-native-svg';

type Props = {
    color: string;
    size: number;
};

export function WarehouseIcon({ color = '#cccccc', size }: Props) {
    return (
        <Svg viewBox='0 0 110 220' fill='none' height={size} width={size - 6}>
            <G fill={color}>
                <Path id='Vector' d='M103 49H7V147.687H103V49Z' />
                <Path id='Vector_2' d='M55 4L105 46.7095V48.9273H5V46.7095L55 4Z' y={1} />
                <Path id='Vector_3' d='M54.9995 214.796L7 147H103L54.9995 214.796Z' />
                <Path id='Vector_4' d='M7 78.5557H103' />
                <Path id='Vector_5' d='M6.86102 116.456H102.861' />
                <Path d='M13 146C13 143.791 11.2091 142 9 142C6.79086 142 5 143.791 5 146H13ZM5 216V220H13V216H5ZM5 146V216H13V146H5Z' />
                <Path d='M105 146C105 143.791 103.209 142 101 142C98.7909 142 97 143.791 97 146H105ZM97 216V220H105V216H97ZM97 146V216H105V146H97Z' />
            </G>
        </Svg>
    );
}
