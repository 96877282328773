import { Panel } from '@/shared/components/ui/Panel';
import Aqua from '../Aqua';
import AquaDetails from '../AquaDetails';

export const AquaManager = () => {
    return (
        <Panel>
            <Panel.Screen>
                <Aqua />
            </Panel.Screen>
            <Panel.Screen>
                <AquaDetails />
            </Panel.Screen>
        </Panel>
    );
};
