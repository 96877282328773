import { useCurrentPlace } from '@/chicken-house/store/CurrentPlaceStore';
import React from 'react';
import LocationName from '../LocationName';
import { Stack } from 'tamagui';

const PlaceHeader = () => {
    const placeName = useCurrentPlace((store) => store.place?.name);

    return null;

    if (!placeName) return null;

    return (
        <Stack pl={12}>
            <LocationName site={placeName || ''} />{' '}
        </Stack>
    );
};

export default PlaceHeader;
