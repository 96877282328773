import { RouteProp, useRoute } from '@react-navigation/native';
import { AppStackParamsList } from '../../../../../navigation/AppStack';
import { useSiloPanel } from '@/warehouse/store/useSiloPanelStore';

export function useCurrentSiloID() {
    const { params } = useRoute<RouteProp<AppStackParamsList, 'WarehouseDetails'>>();
    const selectedSilo = useSiloPanel((store) => store.selectedSiloId);

    return params?.silo?.id || selectedSilo;
}
