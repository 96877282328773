import { useMemo } from 'react';
import { useFeedTypes } from './useFeedTypes';

export function useRationById(rationId: string | undefined) {
    const rations = useFeedTypes();

    const ration = useMemo(() => rations.data?.items.find((r) => r.id === rationId), [rationId, rations.data?.items]);

    return {
        data: rationId ? ration : undefined,
        isLoading: rationId ? rations.isLoading : false,
    };
}
