import { LinearGradient } from 'expo-linear-gradient';
import React, { memo } from 'react';
import { Stack, StackProps } from 'tamagui';

type Props = Pick<StackProps, 'mt' | 'mb' | 'my'>;

const DrawerSeparator = (props: Props) => {
    return (
        <Stack {...props}>
            <LinearGradient
                start={[0, 1]}
                end={[1, 0]}
                style={{ width: '100%', height: 1, opacity: 0.3 }}
                colors={['#ffffff00', '#ffffff', '#ffffff00']}
            />
        </Stack>
    );
};

export default memo(DrawerSeparator);
