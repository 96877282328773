import React from 'react';
import { Polygon } from 'react-native-svg';
import { SiloPartProps } from './props';

export const SiloCeil = (props: SiloPartProps) => {
    const height = props.height || 50;
    const width = props.width || 105;

    function getPoints() {
        return [
            [width / 2, 0],
            [width, height],
            [0, height],
        ];
    }

    return (
        <Polygon
            id='Vector_2'
            points={getPoints()
                .map(([x, y]) => `${x},${y}`)
                .join(' ')}
        />
    );
};
