import { create } from 'zustand';

type CurrentPlaceStore = {
    place: {
        name: string;
    } | null;
    onPlaceNameReceived(name: string): void;
};

export const useCurrentPlace = create<CurrentPlaceStore>((set) => ({
    place: null,
    onPlaceNameReceived(name: string) {
        set({ place: { name } });
    },
}));
