import { BottomSheetFlatList } from '@gorhom/bottom-sheet';
import React from 'react';
import { Platform } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Separator } from 'tamagui';
import { MultiSelectItem, MultiSelectProps, SelectValue } from '../../MultiSelectProps';
import SelectItem from './SelectItem';
import ViewState from '../../../ViewState';

type Props = Pick<MultiSelectProps, 'items'> & {
    value: SelectValue[];
    onSelect: (item: MultiSelectItem) => void;
};

const List = Platform.OS === 'web' ? FlatList : BottomSheetFlatList;

const SelectList = ({ items, value, onSelect }: Props) => {
    return (
        <List
            data={items}
            renderItem={({ item }) => <SelectItem isSelected={value?.includes(item.value)} item={item} onSelect={onSelect} />}
            ItemSeparatorComponent={() => <Separator />}
            style={{ marginBottom: Platform.OS === 'web' ? 0 : 110 }}
            ListEmptyComponent={<ViewState empty search />}
        />
    );
};

export default SelectList;
