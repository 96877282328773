import { BottomSheetFooterProps } from '@gorhom/bottom-sheet';
import React, { PropsWithChildren } from 'react';
import { Stack } from 'tamagui';

export type SheetFooterProps = BottomSheetFooterProps;

const SheetFooter = (props: PropsWithChildren) => <Stack>{props.children}</Stack>;

SheetFooter.displayName = 'SheetFooter';

export { SheetFooter };
