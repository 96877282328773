import merge from 'lodash.merge';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { config } from '../../../config/env';
import { createAppStateStorage } from '../../../config/storage';

export type TesterSettings = {
    /**
     * Custom api url
     */
    apiUrl: string;

    /**
     * If true, it will use mocked data to show camers
     */
    shouldUseMockedChickenHouses: boolean;

    /**
     * If true, new UI will be enabled
     */
    shouldUseNewUI: boolean;
};

type TesterSettingsStore = {
    isEnabled: boolean;
    settings: TesterSettings;
    onUpdateSetting: (settings: Partial<TesterSettings>) => void;
    setEnabled: (enabled: boolean) => void;
};

export const useTesterSettings = create(
    persist<TesterSettingsStore>(
        (set, get) => ({
            isEnabled: false,

            settings: {
                shouldUseNewUI: false,
                shouldUseMockedChickenHouses: false,
                apiUrl: config.baseUrl,
            },

            onUpdateSetting(settings) {
                const current = get().settings;

                set({ settings: merge(current, settings) });
            },

            setEnabled(enabled: boolean) {
                set({ isEnabled: enabled });
            },
        }),
        {
            name: 'alltis.tester-settings',
            storage: createJSONStorage(() => createAppStateStorage()),
            version: 1,
        }
    )
);
