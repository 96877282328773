import { useMutation } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { FeedApi } from '../../api/FeedApi';
import { Ration } from '../../api/types/FeedResponse';

type MoveFeedTypeParams = {
    newData: Ration[];
    feedId: string;
    from: number;
    to: number;
};

export function useMoveFeedType() {
    const feedApi = container.resolve(FeedApi);

    return useMutation({
        mutationFn: (params: MoveFeedTypeParams) => {
            return feedApi.updateRation(params.feedId, {
                offset: params.from - params.to,
            });
        },
    });
}
