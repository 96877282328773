import axios from 'axios';
import { config } from '../env';
import { createAuthErrorInterceptor, createAuthInterceptor } from './interceptors/auth.interceptor';
import { createCacheInterceptor } from './interceptors/cache.interceptor';
import { createFarmInterceptor } from './interceptors/farm.interceptor';
import { createBaseUrlInterceptor } from './interceptors/url.interceptor';

export const alltisApiV2 = axios.create({
    baseURL: config.baseUrl + '/api',
});

alltisApiV2.interceptors.request.use((config) => {
    createAuthInterceptor(config);
    createBaseUrlInterceptor(config);
    createCacheInterceptor(config);
    createFarmInterceptor(config);

    if (__DEV__) {
        config.headers['ngrok-skip-browser-warning'] = '69420';
    }

    return config;
}, Promise.reject);

alltisApiV2.interceptors.response.use(
    (config) => config,
    (error) => {
        createAuthErrorInterceptor(error);

        return Promise.reject(error);
    }
);
