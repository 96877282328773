import React from 'react';
import { Text } from 'tamagui';
import { useRecordStopwatch } from './useRecordStopwatch';

const RecordStopwatch = () => {
    const { minutes, seconds } = useRecordStopwatch();

    return (
        <Text color='#000' marginTop='$3' fontWeight='600'>
            {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </Text>
    );
};

export default RecordStopwatch;
