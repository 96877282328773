import { ServerConfigRepository } from '@/chicken-house/db/server-config/ServerConfigRepository';
import * as NetInfo from '@react-native-community/netinfo';
import { useMutation } from '@tanstack/react-query';
import { getDatabase } from '../../../../config/database';
import { container } from '../../../../config/di';
import { useForegroundPermissions } from 'expo-location';
import { CameraServerNetwork } from '@/chicken-house/entities/CameraServerNetwork';
import { useLogger } from '../../../../config/logging/useLogger';

export function useSaveServerConfig() {
    const logger = useLogger();
    const serverConfigRepository = container.get(ServerConfigRepository);

    const [locationPermission] = useForegroundPermissions();

    return useMutation(['save-server-config'], async (options: CameraServerNetwork) => {
        logger.info('saving server configuration to database', options, 'scan-server');

        // it should return nothing to avoid getting wifi information
        // because if permission has not granted, then we can't get network bssid
        if (!locationPermission?.granted) return null;

        const netInfo = await NetInfo.fetch();

        if (netInfo.type === NetInfo.NetInfoStateType.wifi && netInfo.details.bssid) {
            const savedConfig = await serverConfigRepository.findByBSSID(netInfo.details.bssid);

            const db = getDatabase();

            if (savedConfig) {
                await db.write(async () => {
                    savedConfig.update(() => {
                        savedConfig.ssid = netInfo.details.ssid || '';
                        savedConfig.ip = options.ip || '';
                        savedConfig.port = options.port;
                    });
                });

                return;
            }

            await db.write(async () => {
                serverConfigRepository.create({
                    bssid: netInfo.details.bssid || '',
                    ssid: netInfo.details.ssid || '',
                    ip: options.ip,
                    port: options.port,
                });
            });
        }
    });
}
