import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { useToast } from '@/shared/hooks/useToast';
import { useSaveFeed } from '@/warehouse/hooks/feed/useSaveFeed';
import { FeedFormData } from '@/warehouse/utils/validators/feedSchema';
import { useNavigation } from '@react-navigation/native';
import { Keyboard } from 'react-native';

export function useSubmitRation(rationId: string | undefined) {
    const errorHandler = useErrorHandler();
    const navigation = useNavigation();
    const toast = useToast();

    const saveFeed = useSaveFeed(rationId);

    async function onSubmitRation(feed: FeedFormData) {
        try {
            Keyboard.dismiss();

            await saveFeed.mutateAsync({ feed });

            toast.show({
                title: 'Ração salva',
                message: 'O tipo de ração foi salvo',
            });

            navigation.navigate('FeedManager');
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    return { onSubmitRation, isSaving: saveFeed.isLoading };
}
