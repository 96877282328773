import { FlashList, FlashListProps } from '@shopify/flash-list';
import React from 'react';
import { Separator, Stack } from 'tamagui';

const ReportList = <T,>(props: FlashListProps<T>) => {
    return (
        <Stack flex={1}>
            <FlashList
                {...props}
                estimatedItemSize={props.estimatedItemSize || 400}
                ItemSeparatorComponent={() => <Separator my={22} />}
                contentContainerStyle={{ paddingBottom: 100 }}
            />
        </Stack>
    );
};

export default ReportList;
