import { Stack, styled } from 'tamagui';

export const Touchable = styled(Stack, {
    width: '100%',
    height: 100,
    backgroundColor: '#FED7D7',
    borderRadius: '$6',
    justifyContent: 'center',
    alignItems: 'center',
    pressStyle: {
        backgroundColor: '#FEB2B2',
    },
});
