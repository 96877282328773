import { useCallback, useEffect, useRef, useState } from 'react';
import Zeroconf from 'react-native-zeroconf';
import { isIpv4 } from '../../utils/network';
import { Platform } from 'react-native';
import { useLogger } from '../../../../config/logging/useLogger';

const DNS_DEVICE_QUERY = 'eggcounter';

export function useMDNS() {
    const logger = useLogger();
    const [isScanning, setIsScanning] = useState(false);

    const zeroConfInstance = useRef<Zeroconf>();

    const startScan = useCallback(async () => {
        return new Promise<{ ip: string; port: number }>((res, rej) => {
            if (Platform.OS === 'web') return rej();

            logger.info('starting scan mdns server', null, 'scan-server');

            setIsScanning(true);

            if (isScanning) return;

            const zeroConf = new Zeroconf();

            zeroConf.on('resolved', (service) => {
                logger.info('dns server sucessfully found', { ...service }, 'scan-server');

                const ipv4 = service.addresses.find(isIpv4);

                if (!ipv4) return;

                res({ ip: ipv4, port: service.port });

                zeroConf.stop();
            });

            zeroConf.on('error', rej);

            zeroConf.scan(DNS_DEVICE_QUERY);

            zeroConfInstance.current = zeroConf;
        });
    }, []);

    useEffect(() => {
        return () => {
            zeroConfInstance.current?.stop();
        };
    }, []);

    return { startScan, isScanning };
}
