import ReportChart from './ReportChart';
import ReportContent from './ReportContent';
import ReportForm from './ReportForm';
import ReportExportButton from './ExportReportButton';
import ReportList from './ReportList';
import ReportResultCard from './ReportResultCard';

export const Report = {
    Form: ReportForm,
    Content: ReportContent,
    Card: ReportResultCard,
    Chart: ReportChart,
    List: ReportList,
    ExportButton: ReportExportButton,
};
