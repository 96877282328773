import { useAquaList } from './useAquaList';

export function useAquaById(id: string | null) {
    const { data, ...aqua } = useAquaList();

    return {
        ...aqua,
        data: data?.find((response) => response.aqua.id === id),
    };
}
