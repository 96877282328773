import { useTesterSettings } from '@/user/store/TesterSettingsStore';
import { useFlag } from '../../feature-flag';

export function useNewUIEnabled() {
    const isFlagActive = useNewUIFlag();

    const useNewUiSetting = useTesterSettings(({ settings }) => settings.shouldUseNewUI);

    return useNewUiSetting || isFlagActive;
}

export function useNewUIFlag() {
    return useFlag('NEW_UI_ENABLED', false);
}
