import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { useEffect } from 'react';
import { useAuth } from '../store/AuthStore';
import { useUser } from '../store/UserStore';

export function useSyncLoggedUser() {
    const auth = useAuth((store) => store.auth);

    const errorHandler = useErrorHandler();
    const userStore = useUser();

    useEffect(() => {
        if (auth?.token) {
            userStore.sync().catch((error) => errorHandler.handle(error, false));
        }
    }, [auth?.token]);

    return userStore;
}
