import { SenseMetrics } from '@/sense/entities/Sense';
import { useChartWidth } from '@/shared/components/ui/ScrollableChart/useChartWidth';
import { formatChartValue } from '@/shared/utils/charts';
import dayjs from 'dayjs';
import React from 'react';
import { Dimensions } from 'react-native';
import { VictoryChart, VictoryLegend, VictoryLine, VictoryScatter, VictoryTheme } from 'victory-native';
import { senseChartAxis } from './base/SenseChartAxis';

type Props = {
    metrics: Pick<SenseMetrics, 'timestamp' | 'temperature'>[];
};

const SenseTemperatureChart = (props: Props) => {
    const chartWidth = useChartWidth();

    return (
        <VictoryChart
            width={chartWidth}
            theme={VictoryTheme.material}
            domainPadding={{
                x: Dimensions.get('screen').width / 40,
                y: 20,
            }}
            // minDomain={{
            //     y: RANGE_OPTIONS.temperature.range[0],
            // }}
            // maxDomain={{
            //     y: RANGE_OPTIONS.temperature.range[1],
            // }}
        >
            <VictoryLegend
                orientation='horizontal'
                x={40}
                data={[{ name: 'Temperatura', symbol: { fill: '#e95b5b' } }]}
                style={{ labels: { fontSize: 12 } }}
            />

            <VictoryLine
                data={props.metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.temperature,
                }))}
                labels={({ datum }) => formatChartValue(datum.y, { prefix: 'ºc' })}
                style={{ data: { stroke: '#e95b5b30' } }}
            />

            <VictoryScatter
                data={props.metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.temperature,
                }))}
                labels={() => null}
                style={{ data: { fill: '#a44646' } }}
            />

            {senseChartAxis.x}
            {senseChartAxis.y}
        </VictoryChart>
    );
};

export default SenseTemperatureChart;
