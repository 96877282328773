import { useCallback, useState } from 'react';
import * as Updates from 'expo-updates';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { Platform } from 'react-native';

export function useUpdates() {
    const [isLoading, setIsLoading] = useState(!__DEV__);
    const errorHandler = useErrorHandler();

    const checkForUpdates = useCallback(async () => {
        try {
            const shouldIgnoreUpdates = __DEV__ || Platform.OS === 'web';

            if (shouldIgnoreUpdates) {
                return setIsLoading(false);
            }

            setIsLoading(true);

            const update = await Updates.checkForUpdateAsync();

            if (update.isAvailable) {
                await Updates.fetchUpdateAsync();
                await Updates.reloadAsync();
            }
        } catch (err) {
            errorHandler.handle(err, false);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { checkForUpdates, isLoading };
}
