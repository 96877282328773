import { createContext } from 'react';
import { PopoverProps } from '../PopoverProps';

type PopoverContextType = {
    anchor: HTMLElement | null;
    setAnchor: (element: HTMLElement | null) => void;
    props: PopoverProps;
};

export const PopoverContext = createContext<PopoverContextType>({} as PopoverContextType);
