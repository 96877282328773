import axios from 'axios';
import { createAuthErrorInterceptor, createAuthInterceptor } from './interceptors/auth.interceptor';

export const alltisApi = axios.create({
    baseURL: 'https://alltis.onlosant.com',
});

alltisApi.interceptors.request.use((config) => createAuthInterceptor(config), Promise.reject);

alltisApi.interceptors.response.use(
    (config) => config,
    (error) => {
        createAuthErrorInterceptor(error);

        return Promise.reject(error);
    }
);
