import { useHeaderHeight } from '@react-navigation/elements';
import React from 'react';
import { KeyboardAvoidingView, KeyboardAvoidingViewProps, Platform } from 'react-native';

type Props = KeyboardAvoidingViewProps;

const KeyboardView = (props: Props) => {
    const headerHeight = useHeaderHeight();

    return (
        <KeyboardAvoidingView
            enabled={Platform.OS === 'ios'}
            behavior='padding'
            keyboardVerticalOffset={headerHeight}
            style={{ flex: 1 }}
        >
            {props.children}
        </KeyboardAvoidingView>
    );
};

export default KeyboardView;
