import { ReportFilterData } from '@/reports/hooks/useReportFilter';
import { SenseMetrics } from '@/sense/entities/Sense';
import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';

type GetSenseReportResponse = Array<{
    id: string;
    name: string;
    historic: SenseMetrics[];
}>;

export function useSenseReport(filter: ReportFilterData) {
    return useQuery<GetSenseReportResponse>({
        queryKey: ['sense-report', filter],
        queryFn: async () => {
            const response = await alltisApiV2.get<GetSenseReportResponse>('/sense/historical', {
                params: {
                    ids: filter.entityIds?.join(','),
                    initial_period: filter.startDate,
                    final_period: filter.endDate,
                },
            });

            return response.data;
        },
        enabled: !!filter.entityIds.length,
    });
}
