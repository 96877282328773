import { PropsWithChildren, ReactElement, memo } from 'react';

type Props = PropsWithChildren<{
    isEnabled?: boolean;
}>;

const Guard = memo(({ isEnabled = true, children }: Props) => {
    if (!isEnabled) return null;

    return children as ReactElement;
});

Guard.displayName = 'Guard';

export default Guard;
