import { useStatusBar } from '@/shared/hooks/useStatusBar';
import React from 'react';
import { WebView } from 'react-native-webview';

const PrivacyPolicy = () => {
    useStatusBar('dark-content');

    return (
        <WebView
            source={{
                uri: 'https://bi.alltis.com.br/privacy-policy',
            }}
        />
    );
};

export default PrivacyPolicy;
