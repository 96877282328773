import { useUser } from '@/authentication/store/UserStore';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Text, XStack, useTheme } from 'tamagui';

const UserInformation = () => {
    const theme = useTheme();
    const navigation = useNavigation();

    const name = useUser((state) => state.user?.name);

    useEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <XStack ai='center' pr={16}>
                    <Feather name='user' size={14} color={theme.secondary900.get() as string} />

                    <Text ml={4} color='$secondary900' fontSize={12}>
                        {name}
                    </Text>
                </XStack>
            ),
            headerShadowVisible: true,
        });
    }, [name]);

    return null;
};

export default UserInformation;
