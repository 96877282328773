import Alert from '@/shared/components/ui/Alert';
import { FAB } from '@/shared/components/ui/FAB';
import ViewState from '@/shared/components/ui/ViewState';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { Ration } from '@/warehouse/api/types/FeedResponse';
import FeedCard from '@/warehouse/components/FeedCard';
import { useFeedTypes } from '@/warehouse/hooks/feed/useFeedTypes';
import { useMoveFeedType } from '@/warehouse/hooks/feed/useMoveFeedType';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DraggableFlatList, { DragEndParams } from 'react-native-draggable-flatlist';
import { Stack, Text } from 'tamagui';

const FeedManager = () => {
    useStatusBar('dark-content');

    const navigation = useNavigation();

    const { data: feedTypes, isLoading, isError } = useFeedTypes();

    const draggingItem = useRef<Ration | null>();
    const [sortedFeedTypes, setSortedFeedTypes] = useState<Ration[]>(feedTypes?.items || []);

    const moveFeedType = useMoveFeedType();

    useEffect(() => {
        if (feedTypes?.items) {
            setSortedFeedTypes(feedTypes.items);
        }
    }, [feedTypes]);

    const onDragBegin = useCallback((index: number) => {
        draggingItem.current = sortedFeedTypes[index];
    }, []);

    const onDragEnd = useCallback(({ data, from, to }: DragEndParams<Ration>) => {
        setSortedFeedTypes(data);

        if (!draggingItem.current) return;

        moveFeedType.mutate({
            feedId: draggingItem.current.id,
            from,
            to,
            newData: data,
        });

        draggingItem.current = null;
    }, []);

    return (
        <Stack flex={1}>
            <DraggableFlatList
                ListHeaderComponent={
                    <Stack mb={22}>
                        <Alert
                            message='Dica: Para alterar a posição das rações, pressione e segure no item que deseja mover e arraste com o dedo para a posição desejada.'
                            severity='info'
                            icon='info'
                        />
                    </Stack>
                }
                data={sortedFeedTypes}
                keyExtractor={(item) => item.id}
                contentContainerStyle={{ padding: 22, paddingBottom: 88 }}
                onDragBegin={onDragBegin}
                onDragEnd={onDragEnd}
                renderItem={({ item, drag, getIndex, isActive }) => (
                    <FeedCard feed={item} onDrag={drag} getIndex={getIndex} isActive={isActive} />
                )}
                ListEmptyComponent={<ViewState empty={feedTypes?.items.length === 0} error={isError} loading={isLoading} />}
            />

            <FAB label='Adicionar ração' icon='add' onPress={() => navigation.navigate('FeedRegister')} />
        </Stack>
    );
};

export default FeedManager;
