import { injectable } from 'inversify';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { GetSilosByGroupResponse, GetSilosResponse } from './types/SiloResponse';
import { UpdateSiloPropertiesRequest } from './types/SiloRequest';

@injectable()
export class SiloApi {
    /**
     * Get existing silos
     *
     * @returns existing silos
     */
    async getSilos(): Promise<GetSilosResponse> {
        return (await alltisApiV2.get('/silos/list')).data;
    }

    /**
     * Get silos by a specific group
     *
     * @param groupId - Group identification
     *
     * @returns silos from group
     */
    async getSilosByGroup(groupId: string): Promise<GetSilosByGroupResponse> {
        return (await alltisApiV2.get(`/silos/groups/${groupId}`)).data;
    }

    /**
     * Update properties from specific silo
     */
    async updateSiloProperties(siloId: string, properties: UpdateSiloPropertiesRequest) {
        return (await alltisApiV2.put(`/silos/${siloId}`, properties)).data;
    }
}
