import { SimpleLineIcons } from '@expo/vector-icons';
import React from 'react';
import { Text, XStack, useTheme } from 'tamagui';

type Props = {
    site: string;
    location?: string;
};

const LocationName = ({ site, location }: Props) => {
    const theme = useTheme();

    return (
        <XStack alignItems='center'>
            <SimpleLineIcons name='location-pin' color={theme.secondary900.get() as string} />
            <Text color='$secondary900' numberOfLines={1} ml={5} fontSize={14}>
                {site} {location ? `| ${location}` : ''}
            </Text>
        </XStack>
    );
};

export default LocationName;
