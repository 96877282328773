import React from 'react';
import { Path, Rect } from 'react-native-svg';
import { SiloPartProps } from './props';

export const SiloBase = (props: SiloPartProps) => {
    const height = props.height || 0;
    const width = props.width || 103;

    const getCenterPoints = () => {
        return `M0,0 L${width / 2},${height} L${width},0 L0,0 Z`;
    };

    return (
        <>
            <Path d={getCenterPoints()} strokeLinejoin='round' />

            <Rect x={-3} width={8} height={height} />
            <Rect x={width - 5} width={8} height={height} />
        </>
    );
};
