import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Stack } from 'tamagui';

type Props = {
    color?: 'dark' | 'light';
};

const PlaceSetupIcon = (props: Props) => {
    const navigation = useNavigation();

    function navigateToPlaceScan() {
        return navigation.navigate('PlaceScan');
    }

    if (Platform.OS === 'web') return null;

    return (
        <Stack pl={12}>
            <TouchableOpacity onPress={navigateToPlaceScan} accessibilityLabel='Editar câmeras'>
                <FontAwesome5 name='wifi' size={16} color={props.color === 'light' ? '#ffffff' : '#171923'} />
            </TouchableOpacity>
        </Stack>
    );
};

export default PlaceSetupIcon;
