import * as Sentry from '@sentry/react-native';
import * as Updates from 'expo-updates';
import * as Application from 'expo-application';
import Constants from 'expo-constants';

export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation();

const isEnabled = !__DEV__;

Sentry.init({
    dsn: isEnabled ? 'https://17877cdfd7ab5ad5d4e469d97df2961c@o4505643305598976.ingest.sentry.io/4506101995143168' : '',
    debug: false,
    tracesSampleRate: 1.0,
    attachScreenshot: true,
    attachStacktrace: true,
    attachViewHierarchy: true,
    enabled: isEnabled,
    enableNative: isEnabled,
    integrations: [new Sentry.ReactNativeTracing({ routingInstrumentation })],
    release: Constants.expoConfig?.version,
});

Sentry.configureScope((scope) => {
    scope.setTag('expo-update-id', Updates.updateId);
    scope.setTag('expo-is-embedded-update', Updates.isEmbeddedLaunch);
    scope.setTag('bundle.nativeVersion', Application.nativeApplicationVersion);
    scope.setTag('bundle.version', Constants.expoConfig?.version);
});
