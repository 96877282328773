import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { Farm } from '../entities/Farm';

export function useFarms() {
    return useQuery<Farm[]>({
        queryKey: ['farms'],
        queryFn: async () => (await alltisApiV2.get('/farms/list')).data,
    });
}
