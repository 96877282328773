import { CircularProgress } from '@mui/material';
import React from 'react';
import { SpinnerProps } from './SpinnerProps';

const Spinner = (props: SpinnerProps) => {
    return (
        <CircularProgress
            size={props.size === 'small' ? 20 : 30}
            sx={{
                color: props.color,
            }}
        />
    );
};

export default Spinner;
