import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import SectionContainer from '@/shared/components/ui/SectionContainer';
import { Silo } from '@/warehouse/entities/Silo';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import pluralize from 'pluralize';
import React from 'react';
import { Stack, XStack } from 'tamagui';
import FeedConsumptionChart from './FeedConsumptionChart';
import { WarehouseSupplyColumns } from './WarehouseSupplyColumns';

type Props = {
    silo: Silo;
};

const WarehouseFeedDetails = ({ silo }: Props) => {
    const quantityUnits = useQuantityUnits(silo?.prefer_tons);

    return (
        <Stack>
            <SectionContainer title='Operação'>
                <XStack mb={16}>
                    <LabelAndValue
                        label='Consumo médio por dia'
                        value={quantityUnits.format({
                            perc: silo?.level.consumption.average.percentage,
                            tons: silo?.level.consumption.average.tons,
                        })}
                        containerProps={{ flexGrow: 1 }}
                    />
                </XStack>

                {/* TODO: silo conjugado */}
                {/* {!silo.silo_conjugado ? (
                    <WarehouseSupplyColumns warehouse={silo} showTons={silo.prefer_tons} />
                ) : (
                    <>
                        <Stack mt={16}>
                            <WarehouseSupplyColumns warehouse={silo} showTons={silo.silo_conjugado.mostrar_toneladas} showName />
                        </Stack>

                        <Stack mt={16}>
                            <WarehouseSupplyColumns warehouse={silo.silo_conjugado} showTons={silo.mostrar_toneladas} showName />
                        </Stack>
                    </>
                )} */}

                <WarehouseSupplyColumns silo={silo} showTons={silo.prefer_tons} />

                <XStack mt={8}>
                    <LabelAndValue
                        label='Dias Restantes'
                        value={
                            <>
                                {silo?.level.remaining.days ?? 0} {pluralize('dia', silo?.level.remaining.days)}{' '}
                                {pluralize('restante', silo?.level.remaining.days)}
                            </>
                        }
                        containerProps={{ flexGrow: 1 }}
                    />
                </XStack>
            </SectionContainer>

            <SectionContainer title='Consumo de ração' containerProps={{ px: 12 }}>
                <FeedConsumptionChart consumption={silo.level?.consumption.historic || []} isTons={silo.prefer_tons} />
            </SectionContainer>
        </Stack>
    );
};

export default WarehouseFeedDetails;
