import { create } from 'zustand';

type SensePanelStore = {
    senseId: string | null;
    onSelectSense(aquaId: string): void;
};

export const useSensePanel = create<SensePanelStore>((set) => ({
    senseId: null,
    onSelectSense(senseId) {
        set({ senseId });
    },
}));
