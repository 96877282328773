import { toFixedOrNot } from '@/shared/utils/number';
import React, { cloneElement, ReactElement, useMemo } from 'react';
import { Stack, Text, useTheme } from 'tamagui';

type Props = {
    title: string;
    unit: string;
    value: number;
    range: number[];
    expectedRange: number[];
    icon: ReactElement;
    isDanger?: boolean;
};

type RangeIndicator = {
    position: string;
    value: number;
};

const BAR_WIDTH = 30;

const VerticalBarIndicator = ({ isDanger, ...props }: Props) => {
    const theme = useTheme();

    const getPercentage = () => {
        return (props.value * 100) / props.range[1];
    };

    const rangeIndicators = useMemo(() => {
        const indicators: RangeIndicator[] = [
            {
                position: '0%',
                value: props.range[0],
            },
            {
                position: '100%',
                value: props.range[1],
            },
        ];

        indicators.push({
            position: `${(props.expectedRange[0] * 100) / props.range[1]}%`,
            value: props.expectedRange[0],
        });

        indicators.push({
            position: `${(props.expectedRange[1] * 100) / props.range[1]}%`,
            value: props.expectedRange[1],
        });

        return indicators;
    }, [props.range]);

    return (
        <Stack>
            <Stack gap='$1.5' flexDirection='row' mb='$4' alignItems='center'>
                {cloneElement(props.icon || <></>, {
                    color: isDanger ? theme.red10.get() : theme.secondary900.get(),
                    size: 12,
                })}

                <Text color={isDanger ? '$red10' : '$secondary900'}>{props.title}</Text>
            </Stack>

            <Stack alignSelf='flex-start' ml={25}>
                <Stack flexDirection='row'>
                    <Stack>
                        <Stack
                            width={BAR_WIDTH}
                            height={140}
                            backgroundColor='$gray6'
                            borderWidth={isDanger ? 1 : 0.5}
                            borderColor={isDanger ? '$red10' : '$gray8'}
                            justifyContent='flex-end'
                            overflow='hidden'
                        >
                            <Stack
                                width='100%'
                                height={getPercentage() + '%'}
                                backgroundColor={isDanger ? '$red9' : '$secondary900'}
                            />
                        </Stack>
                    </Stack>

                    {rangeIndicators.map((indicator) => (
                        <Stack
                            position='absolute'
                            bottom={indicator.position}
                            overflow='visible'
                            key={indicator.position}
                            flexDirection='row'
                        >
                            <Stack height={1} backgroundColor='#555555' width={BAR_WIDTH} />

                            <Text
                                position='absolute'
                                color='$gray10'
                                fontSize={9}
                                bottom={-5}
                                right={BAR_WIDTH + 5}
                                textAlign='right'
                            >
                                {indicator.value}
                                {props.unit}
                            </Text>
                        </Stack>
                    ))}

                    <Text
                        ml='$2'
                        color={isDanger ? '$red11' : '$secondary500'}
                        fontWeight='bold'
                        fontSize={10}
                        alignSelf='center'
                        position='absolute'
                        bottom={getPercentage() - 6 + '%'}
                        left={BAR_WIDTH}
                    >
                        {toFixedOrNot(props.value, 2)}
                        {props.unit}
                    </Text>
                </Stack>

                <Stack width={BAR_WIDTH}>
                    <Text mt='$2' color='$secondary900' fontSize='$3' alignSelf='center'>
                        {props.unit}
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default VerticalBarIndicator;
