import { List } from '@/shared/components/ui/List';
import SearchField from '@/shared/components/ui/SearchField';
import ViewState from '@/shared/components/ui/ViewState';
import SiloFilters from '@/warehouse/components/SiloListHeader/SiloFilters';
import WarehouseCard from '@/warehouse/components/WarehouseCard';
import { useFilterSilos } from '@/warehouse/hooks/silos/useFilterSilos';
import { useSilosRefetch } from '@/warehouse/hooks/useSilosRefetch';
import React from 'react';
import { Stack } from 'tamagui';

const Warehouses = () => {
    useSilosRefetch();

    const {
        results: { data, isRefetching, refetch, dataUpdatedAt, isLoading, isError },
        filterState,
        setSearch,
        isFiltering,
    } = useFilterSilos();

    return (
        <Stack flex={1}>
            <List
                data={data}
                renderItem={({ item }) => <WarehouseCard silo={item} />}
                ListHeaderComponent={
                    <Stack mb={24} mt={12}>
                        <SearchField placeholder='Digite o nome do silo' onChangeText={setSearch} />

                        <Stack mt={12}>
                            <SiloFilters filter={filterState} />
                        </Stack>
                    </Stack>
                }
                estimatedItemSize={220}
                contentContainerStyle={{ padding: 16 }}
                refreshing={isRefetching}
                onRefresh={refetch}
                keyboardShouldPersistTaps='always'
                ListEmptyComponent={
                    <ViewState search={isFiltering} empty={data.length === 0} loading={isLoading} error={isError} />
                }
                extraData={dataUpdatedAt}
            />
        </Stack>
    );
};

export default Warehouses;
