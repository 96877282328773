import { toFixedOrNot } from '@/shared/utils/number';
import * as React from 'react';
import Svg, { SvgProps, Path, G, ClipPath, Defs, Rect, Text } from 'react-native-svg';

type Props = {
    value: number;
    hideLabel?: boolean;
    svgProps?: SvgProps;
};

const AquaIcon = ({ value, hideLabel, svgProps }: Props) => {
    const clipPathId = React.useId();

    return (
        <Svg viewBox='0 0 262.759 262.759' {...svgProps} width={110} height={120} key={value}>
            <Defs>
                <ClipPath id={clipPathId}>
                    <Rect
                        id='fillBox'
                        x='-150'
                        y='-200'
                        width='100%'
                        height={value === 0 ? '0%' : '100%'}
                        transform='rotate(180, 55, 35)'
                    />
                </ClipPath>
            </Defs>

            <G>
                <Path
                    d='M210.747 75.576a108.036 108.036 0 0 1-.032 155.046c-21.179 20.73-49.343 32.137-79.301 32.137s-58.122-11.407-79.333-32.137c-21.179-20.698-32.842-48.253-32.842-77.539s11.663-56.84 32.842-77.539l73.406-73.213c3.14-3.108 8.715-3.108 11.823 0l73.437 73.245zm-11.726 143.61a92.086 92.086 0 0 0 0-132.2c0-.032-.032-.032-.064-.064l-67.542-67.35-67.574 67.35c-18.103 17.719-28.036 41.204-28.036 66.164s9.933 48.446 28.004 66.1c18.039 17.654 42.07 27.395 67.606 27.395s49.535-9.74 67.606-27.395z'
                    fill='#acacac'
                />

                <Path
                    d='M189.12 88.011c1.25 1.089 30.407 27.459 24.031 70.49-.609 4.037-4.133 6.921-8.202 6.921-.384 0-.769-.032-1.186-.064-4.518-.641-7.658-4.774-6.985-9.164 5.127-34.604-18.52-55.975-18.744-56.2a7.955 7.955 0 0 1-.609-11.407c3.076-3.331 8.299-3.588 11.695-.576zM203.57 171.667c3.845 2.403 4.998 7.401 2.531 11.15l-4.55 6.985c-1.57 2.403-4.229 3.749-6.985 3.749a8.315 8.315 0 0 1-4.422-1.282c-3.845-2.403-4.998-7.401-2.531-11.15l4.518-6.985c2.468-3.781 7.594-4.87 11.439-2.467z'
                    fill='#cccccc'
                />

                <Path
                    d='M199.021 86.983a92.086 92.086 0 0 1 0 132.2c-18.071 17.655-42.07 27.395-67.606 27.395s-49.567-9.74-67.606-27.395c-18.071-17.654-28.004-41.14-28.004-66.1s9.933-48.446 28.036-66.164l67.574-67.35 67.542 67.35c.032.035.064.035.064.064zm14.129 71.515c6.376-43.031-22.781-69.4-24.031-70.49-3.396-3.012-8.619-2.756-11.695.577a7.954 7.954 0 0 0 .609 11.407c.224.224 23.87 21.596 18.744 56.2-.673 4.39 2.467 8.523 6.985 9.164.417.032.801.064 1.186.064 4.069-.001 7.594-2.882 8.202-6.922zm-7.048 24.319c2.467-3.749 1.314-8.747-2.531-11.15s-8.971-1.314-11.439 2.467l-4.518 6.985c-2.467 3.749-1.314 8.747 2.531 11.15a8.315 8.315 0 0 0 4.422 1.282c2.756 0 5.415-1.346 6.985-3.749l4.55-6.985z'
                    fill='#cccccc'
                />

                <Path
                    d='M47.602 108.05c-7.696 13.656-11.797 29.039-11.797 45.037 0 24.96 9.933 48.446 28.004 66.1 18.039 17.654 42.07 27.395 67.606 27.395 20 0 39.042-5.998 55.046-17.071C77.357 235.803 49.209 163.769 47.602 108.05z'
                    fill='#000000'
                    opacity={0.1}
                />
            </G>

            <G clipPath={`url(#${clipPathId})`}>
                <Path
                    d='M210.747 75.576a108.036 108.036 0 0 1-.032 155.046c-21.179 20.73-49.343 32.137-79.301 32.137s-58.122-11.407-79.333-32.137c-21.179-20.698-32.842-48.253-32.842-77.539s11.663-56.84 32.842-77.539l73.406-73.213c3.14-3.108 8.715-3.108 11.823 0l73.437 73.245zm-11.726 143.61a92.086 92.086 0 0 0 0-132.2c0-.032-.032-.032-.064-.064l-67.542-67.35-67.574 67.35c-18.103 17.719-28.036 41.204-28.036 66.164s9.933 48.446 28.004 66.1c18.039 17.654 42.07 27.395 67.606 27.395s49.535-9.74 67.606-27.395z'
                    fill='#3076af'
                />

                <Path
                    d='M189.12 88.011c1.25 1.089 30.407 27.459 24.031 70.49-.609 4.037-4.133 6.921-8.202 6.921-.384 0-.769-.032-1.186-.064-4.518-.641-7.658-4.774-6.985-9.164 5.127-34.604-18.52-55.975-18.744-56.2a7.955 7.955 0 0 1-.609-11.407c3.076-3.331 8.299-3.588 11.695-.576zM203.57 171.667c3.845 2.403 4.998 7.401 2.531 11.15l-4.55 6.985c-1.57 2.403-4.229 3.749-6.985 3.749a8.315 8.315 0 0 1-4.422-1.282c-3.845-2.403-4.998-7.401-2.531-11.15l4.518-6.985c2.468-3.781 7.594-4.87 11.439-2.467z'
                    fill='#4299e1'
                />

                <Path
                    d='M199.021 86.983a92.086 92.086 0 0 1 0 132.2c-18.071 17.655-42.07 27.395-67.606 27.395s-49.567-9.74-67.606-27.395c-18.071-17.654-28.004-41.14-28.004-66.1s9.933-48.446 28.036-66.164l67.574-67.35 67.542 67.35c.032.035.064.035.064.064zm14.129 71.515c6.376-43.031-22.781-69.4-24.031-70.49-3.396-3.012-8.619-2.756-11.695.577a7.954 7.954 0 0 0 .609 11.407c.224.224 23.87 21.596 18.744 56.2-.673 4.39 2.467 8.523 6.985 9.164.417.032.801.064 1.186.064 4.069-.001 7.594-2.882 8.202-6.922zm-7.048 24.319c2.467-3.749 1.314-8.747-2.531-11.15s-8.971-1.314-11.439 2.467l-4.518 6.985c-2.467 3.749-1.314 8.747 2.531 11.15a8.315 8.315 0 0 0 4.422 1.282c2.756 0 5.415-1.346 6.985-3.749l4.55-6.985z'
                    fill='#4299e1'
                />

                <Path
                    d='M47.602 108.05c-7.696 13.656-11.797 29.039-11.797 45.037 0 24.96 9.933 48.446 28.004 66.1 18.039 17.654 42.07 27.395 67.606 27.395 20 0 39.042-5.998 55.046-17.071C77.357 235.803 49.209 163.769 47.602 108.05z'
                    fill='#000000'
                    opacity={0.1}
                />
            </G>

            <Text fill='#ffffff' fontSize={32} x='50%' y={150} textAnchor='middle' fontWeight={700}>
                {!hideLabel ? `${toFixedOrNot(value, 1)}m³` : '---'}
            </Text>
        </Svg>
    );
};

export default AquaIcon;
