import { SiloDeviceStatus, SiloLevelStatus } from '../entities/WarehouseLevel';

export function getDeviceStatusName(status: SiloDeviceStatus) {
    const deviceMapper: Record<SiloDeviceStatus, string> = {
        INACTIVE: 'Inativo',
        ACTIVE: 'Ativo',
        DISCONNECTED: 'Desconectado',
    };

    return deviceMapper[status];
}

export function getLevelStatusName(status: SiloLevelStatus) {
    const levelMapper: Record<SiloLevelStatus, string> = {
        NORMAL: 'Normal',
        ALERT: 'Crítico',
        CRITICAL: 'Crítico',
    };

    return levelMapper[status];
}
