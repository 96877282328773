const regexIPv4 = /\b(?:\d{1,3}\.){3}\d{1,3}\b/g;

export const isIpv4 = (ip: string): boolean => {
    return !!ip.match(regexIPv4)?.[0];
};

export const getIpv4FromString = (text: string) => {
    const ips = text.match(regexIPv4);
    return ips?.[0] || '';
};

type CreateURLOptions = {
    protocol: 'rtsp' | 'http';
    port: number | string;
    host: string;
    auth?: {
        username?: string;
        password?: string;
    };
    path?: string;
};

export const createURL = (options: CreateURLOptions) => {
    const auth = options.auth;

    const port = String(options.port).replace('/d+/g', '');

    const authString = auth?.username && auth?.password ? `${auth.username}:${auth.password}@` : '';

    let path = options.path;

    if (path?.[0] === '/') {
        path = path.substring(1);
    }

    return `${options.protocol}://${authString}${options.host}:${port}${path ? '/' + path : ''}`;
};
