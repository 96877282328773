import { Panel } from '@/shared/components/ui/Panel';
import React from 'react';
import WarehouseTabs from '../WarehousesTabs';
import WarehouseDetails from '../WarehouseDetails';

const WarehouseManager = () => {
    return (
        <Panel>
            <Panel.Screen>
                <WarehouseTabs />
            </Panel.Screen>
            <Panel.Screen>
                <WarehouseDetails />
            </Panel.Screen>
        </Panel>
    );
};

export default WarehouseManager;
