import { create } from 'zustand';
import { CameraScanResult } from '../entities/CameraScanResult';
import { CameraServerNetwork } from '../entities/CameraServerNetwork';
import { CameraNetworkConfig } from '../utils/validators/cameraNetworkConfigValidator';
import { CameraServerStoreType } from './types/CameraServerStoreType';

export const useCameraServer = create<CameraServerStoreType>((set, get) => ({
    scanResult: undefined,
    networkConfig: {
        shouldUseRemoteServer: true,
    },
    scanStep: 'scanning_dns',

    isSetupCompleted() {
        const { scanResult, networkConfig } = get();

        return !!scanResult?.end && !!networkConfig?.auth && !!networkConfig?.ip;
    },

    onDnsScan: ({ ip, port }: CameraServerNetwork) => {
        const state = get().networkConfig;

        return set({ networkConfig: { ...state, ip, port, shouldUseRemoteServer: false }, scanStep: 'scanning_cameras' });
    },

    onServerConnectionError: () => {
        return set({ networkConfig: undefined, scanStep: 'scanning_dns' });
    },

    onSetup: (config: CameraNetworkConfig) => {
        const state = get().networkConfig;

        return set({
            networkConfig: { ...state, ...config, shouldUseRemoteServer: false },
        });
    },

    onCameraScan: (scanResult: CameraScanResult) => {
        set({ scanResult, scanStep: 'completed' });
    },

    setServerMode: (mode) => {
        set({ networkConfig: { shouldUseRemoteServer: mode === 'remote' } });
    },
}));
