import { RenderIcon } from './ViewStateProps';

type Props = {
    renderIcon?: RenderIcon;
    fallback: JSX.Element;
};

export const ViewStateImage = ({ fallback, renderIcon }: Props) => {
    if (!renderIcon) return fallback;

    return renderIcon();
};
