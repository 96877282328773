import { useAuth } from '@/authentication/store/AuthStore';
import SettingFieldScreen, { SettingScreenRouteProps } from '@/user/screens/SettingFieldScreen';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'tamagui';
import NavigationTheme from '../config/theme/NavigationTheme';
import AuthStack, { AuthStackParams } from './Auth/AuthStack';
import GuestStack, { GuestStackParams } from './Guest/GuestStack';

import PrivacyPolicy from '@/authentication/screens/PrivacyPolicy';
import ReportExport, { ReportExportScreenProps } from '@/reports/screens/ReportExport';
import AppHeader from '@/user/components/AppHeader';
import { useTesterSettingsEnabled } from '@/user/hooks/flags/useTesterSettingsEnabled';
import { Platform } from 'react-native';
import { useNewUIEnabled } from '../config/theme/hooks/useNewUIEnabled';
import { useLargeLayout } from '../hooks/useLargeLayout';
import { MainRoutesParams } from './Auth/Main/types';

export type AppStackParamsList = AuthStackParams &
    GuestStackParams &
    MainRoutesParams & {
        PrivacyPolicy: undefined;
        SettingFieldScreen: SettingScreenRouteProps;
        ReportExport: ReportExportScreenProps;
    };

export const AppStack =
    Platform.OS === 'web' ? createStackNavigator<AppStackParamsList>() : createNativeStackNavigator<AppStackParamsList>();

export function AppStackNavigator() {
    useTesterSettingsEnabled();

    const { auth } = useAuth();
    const theme = useTheme();

    const useNewUI = useNewUIEnabled();
    const isLarge = useLargeLayout();

    return (
        <NavigationTheme>
            {isLarge && !useNewUI && <AppHeader />}

            <AppStack.Navigator
                initialRouteName={auth ? 'Login' : 'Home'}
                screenOptions={{
                    headerTintColor: theme.secondary500.val,
                    animationEnabled: true,
                }}
            >
                {!auth ? GuestStack() : AuthStack()}

                <AppStack.Screen
                    name='PrivacyPolicy'
                    component={PrivacyPolicy}
                    options={{
                        presentation: 'modal',
                        headerTitle: 'Política de Privacidade',
                    }}
                />

                <AppStack.Screen
                    name='SettingFieldScreen'
                    component={SettingFieldScreen}
                    options={({ route }) => ({
                        presentation: 'modal',
                        headerTitle: route.params.toolbarTitle || route.params.title,
                    })}
                />

                {Platform.OS === 'web' && (
                    <AppStack.Screen name='ReportExport' component={ReportExport} options={{ headerShown: false }} />
                )}
            </AppStack.Navigator>
        </NavigationTheme>
    );
}
