import SimpleLineIcons from '@expo/vector-icons/SimpleLineIcons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import React, { forwardRef } from 'react';
import { TextInput, TextInputProps } from 'react-native';
import { Stack, StackProps, useTheme } from 'tamagui';
import { fontSize } from '../TextField';

type Props = TextInputProps & {
    containerProps?: StackProps;
};

const SearchField = forwardRef<TextInput, Props>(({ containerProps, ...props }: Props, ref) => {
    const theme = useTheme();

    return (
        <Stack
            borderWidth={1}
            borderColor='#CBD5E0'
            borderRadius={999}
            jc='center'
            ai='center'
            fd='row'
            height={56}
            px={16}
            {...containerProps}
        >
            <Stack>
                <SimpleLineIcons name='magnifier' color={theme.secondary900.get() as string} size={16} />
            </Stack>

            <Stack flex={1} ml={10}>
                <TextInput
                    {...props}
                    style={[props.style, { flex: 1, fontSize }]}
                    ref={ref}
                    returnKeyType='search'
                    placeholderTextColor={theme.secondary900.get() as string}
                />
            </Stack>

            {!!props.value?.length && (
                <Stack
                    hitSlop={{
                        bottom: 20,
                        left: 20,
                        right: 20,
                        top: 20,
                    }}
                    onPress={() => props.onChangeText?.('')}
                    pressStyle={{ opacity: 0.7 }}
                >
                    <MaterialIcons name='close' color={theme.secondary900.get() as string} size={16} />
                </Stack>
            )}
        </Stack>
    );
});

SearchField.displayName = 'SearchField';

export default SearchField;
