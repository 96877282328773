import React, { forwardRef } from 'react';
import { Platform, TextInput, TextInputProps } from 'react-native';
import FieldBase, { FieldBaseProps } from '../FieldBase';
import { useTheme } from 'tamagui';

type Props = TextInputProps & FieldBaseProps;

export const fontSize = Platform.OS === 'web' ? 16 : 14;

const TextField = forwardRef<TextInput, Props>(({ containerProps, helperText, icon, ...props }: Props, ref) => {
    const theme = useTheme();

    return (
        <FieldBase containerProps={containerProps} icon={icon} helperText={helperText}>
            <TextInput
                {...props}
                placeholderTextColor={theme.secondary900.get()}
                style={[{ flex: 1, height: 52, fontSize }, props.style]}
                ref={ref}
            />
        </FieldBase>
    );
});

TextField.displayName = 'TextField';

export default TextField;
