import { CameraSource } from '@/camera-video/entities/CameraSource';
import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import Spinner from '@/shared/components/ui/Spinner';
import { formatBigNumber } from '@/shared/utils/number';
import FontAwesomeIcon from '@expo/vector-icons/FontAwesome5';
import React, { memo } from 'react';
import { Stack, XStack, YStack } from 'tamagui';
import ChickenHouseCamera from '../../CamerasPreview/ChickenHouseCamera';
import BoxCount from './BoxCount';
import ChickenHouseCardActions from './ChickenHouseCardActions';
import { CardTitle, InfoCard, InfoContent } from './styles';
import LocationName from '../../LocationName';

export type Props = {
    data: ChickenHouseInfo;
    isLoading: boolean;
    onSelectCamera?: (camera: CameraSource | undefined) => void;
};

const ChickenHouseItem = ({ data, isLoading, onSelectCamera }: Props) => {
    return (
        <InfoCard flex={1} cursor='initial' hoverStyle={{ backgroundColor: '#ffffff' }}>
            <XStack ai='center'>
                <Stack flex={1}>
                    {data.site?.name && (
                        <Stack mb={14}>
                            <LocationName site={data.site.name} location={data.location?.name} />
                        </Stack>
                    )}

                    <XStack>
                        <CardTitle mr={12}>{data.name}</CardTitle>
                        <InfoContent>{isLoading && <Spinner size='small' />}</InfoContent>
                    </XStack>

                    <XStack>
                        <Stack mr={24}>
                            <LabelAndValue
                                label='Ovos hoje'
                                value={`${formatBigNumber(data.eggCount?.today)}`}
                                icon={<FontAwesomeIcon name='egg' color='#A0AEC0' size={16} />}
                            />
                        </Stack>

                        <Stack>
                            <LabelAndValue
                                label='Ovos ontem'
                                value={`${formatBigNumber(data.eggCount?.yesterday)}`}
                                icon={<FontAwesomeIcon name='arrow-left' color='#A0AEC0' size={16} />}
                            />
                        </Stack>
                    </XStack>

                    <Stack mt={24}>
                        <BoxCount value={data.eggCount?.boxesToday || 0} />
                    </Stack>
                </Stack>

                <YStack height='100%' justifyContent='space-between'>
                    <Stack alignSelf='flex-end' mb={22}>
                        {data.id && (
                            <ChickenHouseCardActions
                                chickenHouse={data}
                                onOpenCameraPress={() => onSelectCamera?.(data.cameraSource)}
                            />
                        )}
                    </Stack>

                    <Stack width={80} h={50}>
                        <ChickenHouseCamera chickenHouse={data} index={0} onPress={() => onSelectCamera?.(data.cameraSource)} />
                    </Stack>
                </YStack>
            </XStack>
        </InfoCard>
    );
};

export default memo(ChickenHouseItem);
