import { create } from 'zustand';

export type EggsTab = 'eggs' | 'recordings' | 'reports';

type EggManagerStore = {
    /**
     * Current silo tab
     */
    activeTab: EggsTab;

    /**
     * Selected place id
     */
    selectedId: string | null;

    /**
     * On select specific chicken house
     *
     * @param siloId - Chicken house identification
     */
    onSelectChickenHouse(id: string): void;

    /**
     * On tab change
     *
     * @param tab - New tab
     */
    onTabChanged(tab: EggsTab): void;
};

export const useEggManager = create<EggManagerStore>((set) => ({
    activeTab: 'eggs',

    selectedId: null,

    onSelectChickenHouse(selectedId) {
        set({ selectedId });
    },

    onTabChanged(activeTab) {
        set({ activeTab });
    },
}));
