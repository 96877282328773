import { MaterialTopTabNavigationOptions } from '@react-navigation/material-top-tabs';
import { useTheme } from 'tamagui';
import { useLargeLayout } from '../../../hooks/useLargeLayout';
import { Platform } from 'react-native';

export function useHomeTabsOptions(): MaterialTopTabNavigationOptions {
    const isLarge = useLargeLayout();
    const theme = useTheme();

    if (isLarge) {
        return {
            tabBarItemStyle: {
                flexDirection: 'row',
            },
            tabBarInactiveTintColor: '#888',
            tabBarIndicatorStyle: {
                backgroundColor: theme.primary500.get(),
            },
            tabBarStyle: {
                width: 500,
                alignSelf: 'center',
            },
            swipeEnabled: Platform.OS !== 'web',
        };
    }

    return {};
}
