import { MenuView } from '@/shared/components/ui/MenuView';
import Spinner from '@/shared/components/ui/Spinner';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { Ration } from '@/warehouse/api/types/FeedResponse';
import { useDeleteFeed } from '@/warehouse/hooks/feed/useDeleteFeed';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Alert } from 'react-native';
import { Stack } from 'tamagui';

enum FeedAction {
    Delete = 'Delete',
    Edit = 'Edit',
}

type Props = {
    feed: Ration;
};

const FeedMenu = ({ feed }: Props) => {
    const navigation = useNavigation();
    const errorHandler = useErrorHandler();

    const deleteFeed = useDeleteFeed();

    function onDeleteFeed() {
        Alert.alert('Apagar ração', `Tem certeza que deseja apagar a ração ${feed.name}? Essa ação não poderá ser desfeita`, [
            { text: 'Cancelar', isPreferred: true, style: 'cancel' },
            {
                text: 'Sim, apagar',
                style: 'destructive',
                onPress: () => {
                    deleteFeed
                        .mutateAsync({
                            feedId: feed.id,
                        })
                        .catch(errorHandler.handle);
                },
            },
        ]);
    }

    function onEditFeed() {
        navigation.navigate('FeedRegister', { rationId: feed.id });
    }

    if (deleteFeed.isLoading || deleteFeed.isSuccess) {
        return <Spinner color='#000000' />;
    }

    return (
        <Stack>
            <MenuView
                title='Opções da Ração'
                actions={[
                    {
                        id: FeedAction.Edit,
                        title: 'Editar ração',
                    },
                    {
                        id: FeedAction.Delete,
                        title: 'Apagar ração',
                        attributes: {
                            destructive: true,
                        },
                    },
                ]}
                isAnchoredToRight
                style={{ alignSelf: 'flex-start' }}
                onPressAction={({ nativeEvent: { event } }) => {
                    if (event === FeedAction.Edit) {
                        return onEditFeed();
                    }

                    if (event === FeedAction.Delete) {
                        return onDeleteFeed();
                    }
                }}
            >
                <MaterialIcons name='more-vert' size={24} />
            </MenuView>
        </Stack>
    );
};

export default FeedMenu;
