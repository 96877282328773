import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as FileSystem from 'expo-file-system';
import { getDatabase } from '../../../config/database';
import { container } from '../../../config/di';
import { CameraRecordRepository } from '../db/repository/CameraRecordRepository';
import { CameraRecord } from '../entities/CameraRecord';

export function useDeleteCameraRecording() {
    const cameraRecordRepository = container.resolve(CameraRecordRepository);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (recording: Pick<CameraRecord, 'id' | 'path'>) => {
            if (!recording.id) return;

            FileSystem.deleteAsync(recording.path);

            await getDatabase().write(async () => {
                await cameraRecordRepository.deleteById(recording.id!);
            });

            await queryClient.refetchQueries(['cameraRecordings']);
        },
    });
}
