import { Model } from 'lib';
import { field, text, date } from '@nozbe/watermelondb/decorators';
import { tableSchema } from '@nozbe/watermelondb';
import { Schema } from '../../../../config/database';

export class CameraRecordModel extends Model<CameraRecordModel>() {
    static table = 'camera_record';

    @text('path')
    path: string;

    @text('core_place_id')
    corePlaceId: string;

    @field('duration')
    duration: number;

    @date('created_at')
    createdAt: Date;

    static getSchema(): Schema {
        return tableSchema({
            name: 'camera_record',
            columns: [
                { name: 'path', type: 'string' },
                { name: 'duration', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'core_place_id', type: 'string' },
            ],
        });
    }
}
