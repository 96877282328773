import React, { PropsWithChildren } from 'react';
import { Stack, StackProps } from 'tamagui';
import { PanelScreen } from './PanelScreen';
import { PanelProvider } from './context/PanelContext';

type Props = PropsWithChildren<{ containerProps?: StackProps }>;

export const Panel = (props: Props) => {
    return (
        <PanelProvider>
            <Stack flexDirection='row' flex={1} {...props.containerProps}>
                {props.children}
            </Stack>
        </PanelProvider>
    );
};

Panel.Screen = PanelScreen;
