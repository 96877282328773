import AquaCard from '@/aqua/components/AquaCard';
import { useAquaList } from '@/aqua/hooks/useAquaList';
import ViewState from '@/shared/components/ui/ViewState';
import { useMainStatusBar } from '@/shared/hooks/useMainStatusBar';
import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { Spacer, Stack } from 'tamagui';

const Aqua = () => {
    useMainStatusBar();

    const { data, isError, isLoading } = useAquaList();

    return (
        <Stack flex={1}>
            <FlashList
                data={data}
                renderItem={({ item }) => <AquaCard aqua={item.aqua} site={item.site} />}
                ListEmptyComponent={<ViewState empty={data?.length === 0} error={isError} loading={isLoading} />}
                estimatedItemSize={220}
                contentContainerStyle={{ padding: 16 }}
                ItemSeparatorComponent={() => <Spacer />}
            />
        </Stack>
    );
};

export default Aqua;
