import { abbreviation, formatBigNumber, toFixedOrNot } from './number';

type Options = {
    hasBigNumbers?: boolean;
    abbr?: boolean;
    prefix?: string;
};

export const formatChartValue = (value: number, options?: Options) => {
    if (options?.abbr) {
        return abbreviation(value);
    }

    const formatted = options?.hasBigNumbers ? formatBigNumber(value) : toFixedOrNot(value, 1);

    return formatted + options?.prefix || '';
};
