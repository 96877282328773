import { useMemo, useState } from 'react';

type Tab<T extends string> = {
    key: T;
    title: string;
};

type Options<T extends string> = {
    activeTab: T;
};

export function useTabController<TabKey extends string>(tabs: (Tab<TabKey> | boolean)[], options: Options<TabKey>) {
    const [routes] = useState(tabs.filter((t) => !!t) as Tab<TabKey>[]);

    const index = useMemo(() => {
        const routesArr = routes.map((r) => r?.key);

        return routesArr.indexOf(options.activeTab);
    }, [options.activeTab, routes]);

    const getRoute = (index: number) => {
        const routesArr = routes.map((r) => r?.key);

        return routesArr[index] as TabKey;
    };

    return { routes, index, getRoute };
}
