import { useQuery } from '@tanstack/react-query';
import { container } from '../../../config/di';
import { CameraRecordRepository } from '../db/repository/CameraRecordRepository';
import { CameraRecord } from '../entities/CameraRecord';

export function useCameraRecordings() {
    const cameraRecordRepository = container.resolve(CameraRecordRepository);

    return useQuery<CameraRecord[]>({
        queryKey: ['cameraRecordings'],
        queryFn: () => cameraRecordRepository.findAllSortByDateDesc(),
    });
}
