import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { Platform, StatusBar, StatusBarStyle } from 'react-native';

type BackgroundColor = 'transparent' | '#ffffff' | '#171923';

export function useStatusBar(options: StatusBarStyle, _: BackgroundColor = '#ffffff') {
    useFocusEffect(
        useCallback(() => {
            StatusBar.setBarStyle(options, true);

            if (Platform.OS === 'android') {
                StatusBar.setTranslucent(true);
                StatusBar.setBackgroundColor('transparent');
            }
        }, [options])
    );
}
