import Report from '@/reports/screens/Report';
import { ReportProps } from '@/reports/screens/Report/ReportProps';
import { useSiloResupplyReport } from '@/warehouse/hooks/report/useWarehouseReport';
import React from 'react';
import ResupplyReportContent from '../ReportContent/ResupplyReportContent';

type Props = Pick<ReportProps, 'filter'> & {
    enabled: boolean;
};

const ResupplyReport = ({ filter, enabled }: Props) => {
    const report = useSiloResupplyReport(
        {
            endDate: filter.data.endDate,
            startDate: filter.data.startDate,
            warehouseIds: filter.data.entityIds,
        },
        { enabled }
    );

    return (
        <Report
            type='silo-resupply'
            title='Relatório de Reabastecimento'
            data={report.data || []}
            filter={filter}
            renderReportItem={(item) => <ResupplyReportContent report={item} />}
            options={{ hideFilters: true }}
            status={{ isError: report.isError, isLoading: report.isLoading }}
        />
    );
};

export default ResupplyReport;
