import { Text, XStack, styled } from 'tamagui';
import { Feather } from '@expo/vector-icons';

export const AlertContainer = styled(XStack, {
    p: 15,
    ai: 'center',
    borderRadius: 12,
    variants: {
        severity: {
            success: {
                bg: '$green3',
            },
            error: {
                bg: '$red3',
            },
            info: {
                bg: '$blue3',
            },
            warn: {
                bg: '$orange3',
            },
        },
    },
    defaultVariants: {
        severity: 'success',
    },
});

export const AlertIcon = styled(Feather, {
    name: 'check',
    variants: {
        severity: {
            success: {
                // @ts-ignore
                name: 'check-circle',
                color: '$green11',
            },
            error: {
                // @ts-ignore
                name: 'alert-circle',
                color: '$red11',
            },
            info: {
                // @ts-ignore
                name: 'info',
                color: '$blue11',
            },
            warn: {
                // @ts-ignore
                name: 'alert-triangle',
                color: '$orange11',
            },
        },
    },
    defaultVariants: {
        severity: 'success' as unknown as undefined,
    },
});

export const AlertText = styled(Text, {
    variants: {
        severity: {
            success: {
                color: '$green11',
            },
            error: {
                color: '$red11',
            },
            info: {
                color: '$blue11',
            },
            warn: {
                color: '$orange11',
            },
        },
    },
    defaultVariants: {
        severity: 'success',
    },
});
