import { useMutation } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { FeedApi } from '../../api/FeedApi';
import { useFeedTypes } from './useFeedTypes';

type SaveFeedParams = {
    feedId: string;
};

export function useDeleteFeed() {
    const feedApi = container.resolve(FeedApi);
    const { refetch: refetchFeedTypes } = useFeedTypes();

    return useMutation({
        mutationFn: (params: SaveFeedParams) => {
            return feedApi.deleteRation(params.feedId);
        },

        onSettled() {
            refetchFeedTypes();
        },
    });
}
