import React, { PropsWithChildren } from 'react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { queryClient } from './queryClient';
import { persister } from './cacheStorage';
import { Platform } from 'react-native';
import { QueryClientProvider } from '@tanstack/react-query';

const disableQueryPersistor = Platform.OS === 'web';

const CacheProvider = (props: PropsWithChildren) => {
    if (disableQueryPersistor) {
        return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
    }

    return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
                persister,
                dehydrateOptions: {
                    shouldDehydrateQuery: ({ queryKey }) => queryKey?.includes('warehouses'),
                },
            }}
        >
            {props.children}
        </PersistQueryClientProvider>
    );
};

export { CacheProvider };
