import { useAuth } from '@/authentication/store/AuthStore';
import { HttpStatusCode, InternalAxiosRequestConfig, isAxiosError } from 'axios';
import { Platform } from 'react-native';

export function createAuthInterceptor(config: InternalAxiosRequestConfig) {
    const tokenParam = parseQueryParamToken();

    if (tokenParam) {
        config.headers.Authorization = `Bearer ${tokenParam}`;
        return config;
    }

    const user = useAuth.getState().auth;

    if (user) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }

    return config;
}

export function parseQueryParamToken() {
    if (Platform.OS === 'web') {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        return token;
    }

    return null;
}

export function createAuthErrorInterceptor(error: unknown) {
    if (isAxiosError(error)) {
        if (error.response?.status === HttpStatusCode.Unauthorized) {
            useAuth.getState().logout();
        }
    }
}
