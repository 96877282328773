import TextField from '@/shared/components/ui/TextField';
import { Feather } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { ComponentProps, useRef } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Stack, Text } from 'tamagui';
import { AppStackParamsList } from '../../../../navigation/AppStack';
import Button from '@/shared/components/ui/Button';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { Platform } from 'react-native';

export type SettingScreenRouteProps = {
    toolbarTitle?: string;
    title: string;
    description: string;
    placeholder?: string;
    icon?: ComponentProps<typeof Feather>['name'];
    defaultValue?: string;
    onSubmit?: (value: string) => void;
};

const SettingFieldScreen = () => {
    const { params } = useRoute<RouteProp<AppStackParamsList, 'SettingFieldScreen'>>();
    const navigation = useNavigation();

    useStatusBar(Platform.OS === 'ios' ? 'light-content' : 'dark-content');

    const fieldValue = useRef<string>(params?.defaultValue || '');

    const safeArea = useSafeAreaInsets();

    function handleSubmit() {
        params?.onSubmit?.(fieldValue.current);
        navigation.goBack();
    }

    return (
        <Stack pb={safeArea.bottom + 24} p={30} background='#fff'>
            <Text mb={6} fontSize={16} fontWeight='600'>
                {params.title}
            </Text>

            <Text mb={12} fontSize={12} color='$secondary900'>
                {params.description}
            </Text>

            <TextField
                onChangeText={(value) => (fieldValue.current = value)}
                defaultValue={params.defaultValue}
                icon={<Feather name={params.icon || 'key'} />}
                placeholder={params.placeholder}
                autoCapitalize='none'
                onSubmitEditing={handleSubmit}
                autoFocus
            />

            <Button mt={24} color='secondary' icon={<Feather name='check-square' />} onPress={handleSubmit}>
                Salvar configuração
            </Button>
        </Stack>
    );
};

export default SettingFieldScreen;
