import { User } from '@/authentication/entities/User';
import AppLogo from '@/shared/components/ui/AppLogo';
import { Image } from 'expo-image';
import React from 'react';
import { DimensionValue } from 'react-native';

type Props = {
    user: User | undefined;
    w?: DimensionValue;
    h?: DimensionValue;
};

const CompanyLogo = ({ user, w = 400, h = 100 }: Props) => {
    if (!user?.organization.logo_uri) {
        return <AppLogo tintColor={user?.organization.header_font_color} mode='white' />;
    }

    return <Image source={user.organization.logo_uri} contentFit='contain' style={{ width: w, height: h }} />;
};

export default CompanyLogo;
