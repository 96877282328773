import { Report as BaseReport } from '@/reports/components';
import ReportControllerForm from '@/reports/components/ReportControlledForm';
import { Stack } from 'tamagui';
import { ReportProps } from './ReportProps';

function Report<TData>({ filter, ...props }: ReportProps<TData>) {
    const hasSelectedItem = !!filter.data?.entityIds?.length;

    return (
        <Stack flex={1}>
            {!props.options?.hideFilters && <ReportControllerForm filter={filter} {...props} />}

            <BaseReport.Content
                title={props.title}
                options={{
                    empty: !props.data?.length || !hasSelectedItem,
                    error: (props.status.isError && hasSelectedItem) || false,
                    loading: (props.status.isLoading && hasSelectedItem) || false,
                }}
            >
                {props.renderContent ? (
                    props.renderContent(props.data)
                ) : (
                    <BaseReport.List data={props.data} renderItem={({ item }) => <>{props.renderReportItem?.(item)}</>} />
                )}
            </BaseReport.Content>

            {!props.options?.hideExport && (
                <BaseReport.ExportButton
                    filter={{
                        entityType: props.type,
                        entityIds: filter.data.entityIds,
                        startDate: filter.data.startDate,
                        endDate: filter.data.endDate,
                    }}
                />
            )}
        </Stack>
    );
}

export default Report;
