import ChickenHouseSelect from '@/chicken-house/components/ChickenHouseSelect';
import { EggsReportForm } from '@/chicken-house/entities/reports/EggsReportForm';
import { useEggManager } from '@/chicken-house/store/useEggManagerStore';
import { Report } from '@/reports/components';
import { RouteProp, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { AppStackParamsList } from '../../../../navigation/AppStack';
import EggReportContent from './EggReportContent';

const EggsReport = () => {
    const eggManager = useEggManager();

    const {
        params = {
            chickenHouse: {
                id: eggManager.selectedId,
            },
        },
    } = useRoute<RouteProp<AppStackParamsList, 'EggsReport'>>();

    const eggsReportForm = useForm<EggsReportForm>({
        defaultValues: {
            startDate: dayjs().subtract(8, 'days').startOf('day').toDate(),
            endDate: dayjs().endOf('day').toDate(),
            chickenHouseIds: params.chickenHouse.id ? [params.chickenHouse.id] : [],
        },
    });

    useEffect(() => {
        if (!params?.chickenHouse?.id) return;

        if (params) {
            eggsReportForm.setValue('chickenHouseIds', [params.chickenHouse.id]);
        }
    }, [params.chickenHouse?.id]);

    const filter = useWatch({ control: eggsReportForm.control });

    return (
        <FormProvider {...eggsReportForm}>
            <Report.Form control={eggsReportForm.control}>
                <Controller
                    name='chickenHouseIds'
                    render={({ field: { onChange, value } }) => <ChickenHouseSelect onChange={onChange} value={value} />}
                    control={eggsReportForm.control}
                />
            </Report.Form>

            <EggReportContent filter={filter as EggsReportForm} />

            <Report.ExportButton
                filter={{
                    entityType: 'egg-vision',
                    startDate: filter.startDate,
                    endDate: filter.endDate,
                    entityIds: filter.chickenHouseIds,
                }}
            />
        </FormProvider>
    );
};

export default EggsReport;
