import { useContext } from 'react';
import { PanelContext } from '../context/PanelContext';

export function usePanel() {
    const context = useContext(PanelContext);

    return {
        isPanel: context?.isPanel || false,
    };
}
