import { AquaConsumption } from '@/aqua/entities/AquaConsumption';
import { useChartWidth } from '@/shared/components/ui/ScrollableChart/useChartWidth';
import { fontSize } from '@/shared/components/ui/TextField';
import { WarehouseConsumption } from '@/warehouse/entities/WarehouseConsumption';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { Stack } from 'tamagui';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLegend, VictoryTheme } from 'victory-native';

type Props = {
    feed?: WarehouseConsumption[];
    aqua: AquaConsumption['historic'];
    isTons?: boolean;
};

const AquaConsumptionChart = ({ feed, aqua, isTons = false }: Props) => {
    const ITEMS_QUANTITY = aqua.length;

    const dimensions = useWindowDimensions();

    const chartWidth = useChartWidth(dimensions.width);

    const quantityUnits = useQuantityUnits(isTons);

    const feedConsumptionValues = feed?.map((item) => {
        return quantityUnits.getValue({
            perc: item.consumption_perc,
            tons: item.consumption_tons,
        });
    });

    const aquaConsumptionValues = aqua?.map((item) => {
        return item.value;
    });

    const horizontalLabels = aqua?.map((item) => dayjs(item.timestamp).format('DD/MM')).slice(ITEMS_QUANTITY * -1);

    const feedConsumption = feedConsumptionValues?.slice(ITEMS_QUANTITY * -1);
    const aquaConsumption = aquaConsumptionValues?.slice(ITEMS_QUANTITY * -1);

    const hasFeed = !!feed && !!feedConsumption;

    const legendLabels = useMemo(() => {
        const labels = [{ name: 'Água', symbol: { fill: '#95cfff' } }];

        if (hasFeed) {
            labels.push({ name: 'Ração', symbol: { fill: '#E9AD5B' } });
        }

        return labels;
    }, [hasFeed]);

    return (
        <Stack ml={10}>
            <VictoryChart
                theme={VictoryTheme.material}
                width={chartWidth}
                domainPadding={{
                    x: Platform.select({
                        native: 10,
                        web: 30,
                    }),
                    y: 30,
                }}
            >
                <VictoryLegend orientation='horizontal' x={40} data={legendLabels} />

                <VictoryAxis
                    label='Data'
                    style={{
                        axisLabel: { padding: 40 },
                    }}
                />

                <VictoryAxis dependentAxis offsetY={100} offsetX={30} />

                <VictoryBar
                    data={aquaConsumption.map((quantity, index) => ({
                        x: horizontalLabels[index],
                        y: quantity,
                    }))}
                    labels={aquaConsumption.map((quantity) => {
                        return `${quantity.toFixed(0)}m³`;
                    })}
                    style={{
                        labels: {
                            fontSize: fontSize - 2,
                        },
                        data: {
                            fill: 'rgba(66, 153, 225, 0.3)',
                        },
                    }}
                />
            </VictoryChart>
        </Stack>
    );
};

export default AquaConsumptionChart;
