import { useFilter } from '@/shared/hooks/useFilter';
import { useSearch } from '@/shared/hooks/useSearch';
import { useSilos } from './useSilos';
import { useMemo, useState } from 'react';
import { SiloDeviceStatus, SiloLevelStatus } from '@/warehouse/entities/WarehouseLevel';

export type SilosFilterState = {
    silos: string[];
    status: (SiloLevelStatus | SiloDeviceStatus)[];
};

export function useFilterSilos() {
    const [search, setSearch] = useState('');

    const filterState = useFilter<SilosFilterState>();

    const { data, ...fetchData } = useSilos();

    const { results } = useSearch(data?.silos || [], {
        search,
        transform: (item) => [item.name, item.site?.name || ''],
    });

    const filteredResults = useMemo(() => {
        const { hasFilter, filter } = filterState;

        return results.filter((silo) => {
            if (hasFilter('silos') && !filter.silos?.includes(silo.name)) {
                return false;
            }

            if (
                hasFilter('status') &&
                !filter.status?.includes(silo.device_status) &&
                !filter.status?.includes(silo.level.status)
            ) {
                return false;
            }

            return true;
        });
    }, [results, filterState.filter]);

    return {
        results: {
            ...fetchData,
            data: filteredResults,
        },
        filterState,
        setSearch,
        isFiltering: filterState.hasFilter() || !!search,
    };
}
