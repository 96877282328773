import { useCallback } from 'react';
import { useLogger } from '../../../config/logging/useLogger';
import { useToast } from './useToast';
import { Logger } from '../../../config/logging';

type HandleErrorConfig = {
    title?: string;
    message?: string;
};

export function logError(err: unknown) {
    console.log(err);

    Logger.setContext('error', { data: err });

    if (err instanceof Error) {
        Logger.addBreadcrumb({
            message: err?.message,
            data: err,
            level: 'error',
            timestamp: new Date().getTime(),
        });
    }
}

export function useErrorHandler() {
    const toast = useToast();
    const logger = useLogger();

    const handle = useCallback(
        (err: unknown, config?: HandleErrorConfig | false) => {
            logError(err);

            if (config === false) return;

            return toast.show({
                title: config?.title || 'Ocorreu um erro',
                message: config?.message || 'Tente novamente mais tarde',
                type: 'error',
            });
        },
        [toast, logger]
    );

    return {
        handle,
    };
}
