import { useMutation } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { FeedApi } from '../../api/FeedApi';
import { CreateRationRequest } from '../../api/types/FeedRequest';
import { useFeedTypes } from './useFeedTypes';

type SaveFeedParams = {
    feed: CreateRationRequest;
};

export function useSaveFeed(feedId?: string) {
    const feedApi = container.resolve(FeedApi);
    const { refetch: refetchFeedTypes } = useFeedTypes();

    return useMutation({
        mutationFn: (params: SaveFeedParams) => {
            params.feed.density = Number(params.feed.density.toFixed(2));

            if (feedId) {
                return feedApi.updateRation(feedId, params.feed);
            }

            return feedApi.createRation(params.feed);
        },

        onSettled() {
            refetchFeedTypes();
        },
    });
}
