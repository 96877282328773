import Popover from '@/shared/components/ui/Popover';
import { Sheet } from '@/shared/components/ui/Sheet';
import { SheetFooter } from '@/shared/components/ui/Sheet/SheetFooter';
import { Aviary } from '@/user/entities/Aviary';
import { Farm } from '@/user/entities/Farm';
import { useSelectedFarm } from '@/user/store/SelectedFarmStore';
import { Portal } from '@gorhom/portal';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { Stack } from 'tamagui';
import FarmSelectorContent from './FarmSelectorContent';
import FarmSelectorFooter from './FarmSelectorFooter';

type Props = PropsWithChildren;

const FarmSelector = ({ children }: Props) => {
    const popoverRef = useRef<Popover>(null);
    const sheetRef = useRef<Sheet>(null);

    const store = useSelectedFarm();

    const [selectedFarm, setSelectedFarm] = useState<Farm | null>(store.farm);
    const [selectedAviary, setSelectedAviary] = useState<Aviary | null>(store.aviary);

    useEffect(() => {
        setSelectedFarm(store.farm);
        setSelectedAviary(store.aviary);
    }, [store.aviary, store.farm]);

    const [isOpen, setIsOpen] = useState(false);

    function onConfirm() {
        store.updateSelectedFarm(selectedFarm, selectedAviary);

        sheetRef.current?.close();
        popoverRef.current?.close();
    }

    if (Platform.OS === 'web') {
        return (
            <Popover
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                popoverRef={popoverRef}
                keepMounted
            >
                <Popover.Trigger>{children}</Popover.Trigger>

                <Popover.Content>
                    <Stack p={16} w={400}>
                        <FarmSelectorContent
                            aviary={selectedAviary}
                            farm={selectedFarm}
                            onChangeAviary={setSelectedAviary}
                            onChangeFarm={setSelectedFarm}
                        />

                        <FarmSelectorFooter onConfirm={onConfirm} />
                    </Stack>
                </Popover.Content>
            </Popover>
        );
    }

    return (
        <>
            <TouchableOpacity activeOpacity={0.7} onPress={() => setIsOpen(true)}>
                {children}
            </TouchableOpacity>

            <Portal>
                {isOpen && (
                    <Sheet
                        index={isOpen ? 0 : -1}
                        enablePanDownToClose
                        snapPoints={[320]}
                        onClose={() => setIsOpen(false)}
                        footerComponent={(footerProps) => (
                            <SheetFooter {...footerProps}>
                                <FarmSelectorFooter onConfirm={onConfirm} />
                            </SheetFooter>
                        )}
                        ref={sheetRef}
                    >
                        <FarmSelectorContent
                            aviary={selectedAviary}
                            farm={selectedFarm}
                            onChangeAviary={setSelectedAviary}
                            onChangeFarm={setSelectedFarm}
                        />
                    </Sheet>
                )}
            </Portal>
        </>
    );
};

export default FarmSelector;
