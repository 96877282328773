import { MMKV } from 'react-native-mmkv';
import { StateStorage } from 'zustand/middleware';

const appStorage = new MMKV({
    id: '@alltis/app-storage',
});

export const getAppStorage = () => appStorage;

export function createAppStateStorage(): StateStorage {
    return {
        getItem: (name) => appStorage.getString(name) || null,
        removeItem: (name) => appStorage.delete(name),
        setItem: (name, value) => appStorage.set(name, value),
    };
}
