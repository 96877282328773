import * as FileSystem from 'expo-file-system';
import * as IntentLauncher from 'expo-intent-launcher';
import { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import FileViewer from 'react-native-file-viewer';

export function useOpenReport() {
    const [isLoading, setIsLoading] = useState(false);

    const share = useCallback(async (uri: string) => {
        try {
            setIsLoading(true);

            if (Platform.OS === 'android') {
                const cUri = await FileSystem.getContentUriAsync(uri);

                await IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                    data: cUri,
                    flags: 1,
                    type: 'application/pdf',
                });
            }

            if (Platform.OS === 'ios') {
                await FileViewer.open(uri);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { share, isLoading };
}
