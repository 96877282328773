export const RANGE_OPTIONS = {
    temperature: {
        range: [0, 45],
        expected: [15, 25],
    },
    humidity: {
        range: [0, 100],
        expected: [40, 60],
    },
    co2: {
        range: [0, 3000],
        expected: [400, 1000],
    },
};
