import * as VideoThumbnails from 'expo-video-thumbnails';
import * as FS from 'expo-file-system';
import { useQuery } from '@tanstack/react-query';

export function useVideoThumbnail(videoPath: string) {
    const { data, isLoading } = useQuery({
        queryKey: ['videoThumbnail', videoPath],
        queryFn: async () => {
            const fileExists = await FS.getInfoAsync(videoPath);

            if (fileExists?.exists) {
                return VideoThumbnails.getThumbnailAsync(videoPath);
            }
        },
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        cacheTime: 99999,
        staleTime: 99999,
        retry: false,
    });

    return {
        thumbnail: data,
        isLoading,
    };
}
