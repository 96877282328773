import { useQuery } from '@tanstack/react-query';
import { useSetupPushNotifications } from './useSetupPushNotifications';

export function usePushNotificationToken() {
    const { getPushToken } = useSetupPushNotifications();

    return useQuery({
        queryKey: ['@notification/push-token'],
        queryFn: () => getPushToken(),
    });
}
