import { Module } from 'lib';
import { Container } from 'inversify';
import { StreamApi } from './api/StreamApi';
import { ServerConfigRepository } from './db/server-config/ServerConfigRepository';

export class ChickenHouseModule implements Module {
    bindDependencies(container: Container): void {
        container.bind(StreamApi).toSelf();
        container.bind(ServerConfigRepository).toSelf();
    }
}
