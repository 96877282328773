import { Image } from 'expo-image';
import React from 'react';
import { DimensionValue } from 'react-native';

type Props = {
    mode?: 'default' | 'white';
    tintColor?: string;
    w?: DimensionValue;
    h?: DimensionValue;
};

const AppLogo = ({ mode = 'default', w = 110, h = 18, tintColor }: Props) => {
    return (
        <Image
            source={
                mode === 'default'
                    ? require('../../../../../assets/logo/alltis_logo.png')
                    : require('../../../../../assets/logo/alltis_logo_white.png')
            }
            contentFit='contain'
            style={{ width: w, height: h }}
            tintColor={tintColor}
        />
    );
};

export default AppLogo;
