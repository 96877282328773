import { injectable } from 'inversify';
import { alltisApi } from '../../../config/api/alltisApi';
import { GetWarehousesResponse } from './types/WarehouseResponse';

@injectable()
export class WarehouseApi {
    /**
     * Get all warehouses to show
     * @deprecated use SiloApi
     * @returns found warehouses
     */
    async getWarehouses(): Promise<GetWarehousesResponse> {
        return (await alltisApi.get('/table-data')).data;
    }

    /**
     * Toggle tons visibility
     * @param deviceId - ID to update
     * @param shouldShowTons - If `true`, it will show tons, if false, percentage
     */
    async updateTons(deviceId: string, shouldShowTons: boolean): Promise<void> {
        await alltisApi.post('/update-mostrar-toneladas/' + deviceId, {
            mostrarToneladas: shouldShowTons,
        });
    }

    /**
     * Updates warehouse feed type
     * @param deviceId - Device identification
     * @param feedId - Feed type identification
     */
    async updateWarehouseFeed(deviceId: string, feedId: string) {
        await alltisApi.post('/update-tipo-racao/' + deviceId, {
            tipoRacaoId: feedId,
        });
    }
}
