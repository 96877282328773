import * as Sentry from '@sentry/react-native';
import './sentry';

export const withLogging = Sentry.wrap;

export const Logger = {
    ...Sentry,

    captureException(error: unknown) {
        Logger.setContext('error', { data: error });

        if (error instanceof Error) {
            Logger.addBreadcrumb({
                message: error?.message,
                data: error,
                level: 'error',
                timestamp: new Date().getTime(),
            });
        }

        return Sentry.captureException(error);
    },

    info(message: string, data?: Record<PropertyKey, unknown> | null, category?: string) {
        return Sentry.addBreadcrumb({
            level: 'info',
            type: 'debug',
            message,
            data: data || {},
            category,
        });
    },

    warn(message: string, data?: Record<PropertyKey, unknown> | null, category?: string) {
        return Sentry.addBreadcrumb({
            level: 'warning',
            type: 'debug',
            message,
            data: data || {},
            category,
        });
    },
};
