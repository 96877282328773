import PlaceEggs from '@/chicken-house/screens/PlaceEggs';
import { WarehouseIcon } from '@/shared/components/ui/Icons/WarehouseIcon';
import { FontAwesome6 } from '@expo/vector-icons';
import { useMemo } from 'react';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';
import { DrawerSection } from '../types';
import WarehouseTabs from '@/warehouse/screens/WarehousesTabs';
import WarehouseManager from '@/warehouse/screens/WarehouseManager';
import Profile from '@/user/screens/Profile';
import Sense from '@/sense/screens/Sense';
import { SenseManager } from '@/sense/screens/SenseManager';
import { AquaManager } from '@/aqua/screens/AquaManager';
import Aqua from '@/aqua/screens/Aqua';
import { useSenseEnabled } from '@/sense/hooks/flags/useSenseEnabled';

export function useMainRoutes(): DrawerSection[] {
    const isLarge = useLargeLayout();

    const isSenseEnabled = useSenseEnabled();

    const mainRoutes = useMemo(
        (): DrawerSection[] => [
            {
                routes: [
                    {
                        title: 'Produção de Ovos',
                        key: 'Places',
                        component: PlaceEggs,
                        icon: ({ color }) => <FontAwesome6 name='house' size={16} color={color} />,
                    },
                    {
                        title: 'Armazenamento de Silos',
                        key: 'Warehouses',
                        component: isLarge ? WarehouseManager : WarehouseTabs,
                        icon: ({ color }) => <WarehouseIcon size={22} color={color} />,
                    },
                    {
                        title: 'Consumo de Água',
                        key: 'Aqua',
                        component: isLarge ? AquaManager : Aqua,
                        icon: ({ color }) => <FontAwesome6 name='droplet' size={16} color={color} />,
                    },
                    {
                        title: 'Ambiência',
                        key: 'Sense',
                        component: isLarge ? SenseManager : Sense,
                        icon: ({ color }) => <FontAwesome6 name='temperature-three-quarters' size={16} color={color} />,
                        hide: !isSenseEnabled,
                    },
                ],
            },
            {
                routes: [
                    {
                        title: 'Ajustes',
                        key: 'Profile',
                        component: Profile,
                        icon: ({ color }) => <FontAwesome6 name='sliders' size={18} color={color} />,
                    },
                ],
            },
        ],
        []
    );

    return mainRoutes;
}
