import { SiloDeviceStatus, SiloLevelStatus } from '@/warehouse/entities/WarehouseLevel';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import React, { ReactNode, useMemo } from 'react';

type Props = {
    levelStatus?: SiloLevelStatus;
    deviceStatus: SiloDeviceStatus;
};

const SiloStatusIcon = ({ deviceStatus, levelStatus }: Props) => {
    const statusIcon = useMemo(() => {
        const alertStatusIconMap: Record<Exclude<SiloLevelStatus | SiloDeviceStatus, 'ACTIVE'>, ReactNode> = {
            ALERT: <FontAwesome name='warning' color='#DD4545' size={16} />,
            CRITICAL: <FontAwesome name='warning' color='#DD4545' size={16} />,
            NORMAL: <FontAwesome name='check-circle' color='#119911' size={16} />,
            INACTIVE: <FontAwesome name='minus-circle' color='#CCCCCC' size={18} />,
            DISCONNECTED: <MaterialIcons name='signal-wifi-off' color='#CCCCCC' size={18} />,
        };

        if (deviceStatus === 'ACTIVE' && levelStatus) {
            return alertStatusIconMap[levelStatus];
        }

        return alertStatusIconMap[deviceStatus];
    }, [deviceStatus, levelStatus]);

    return statusIcon;
};

export default SiloStatusIcon;
