import { injectable } from 'inversify';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { CreateRationRequest, UpdateRationRequest } from './types/FeedRequest';
import { GetRationsResponse, Ration } from './types/FeedResponse';

@injectable()
export class FeedApi {
    /**
     * Get all existing ration types
     * @returns found ration types
     */
    async getRations(): Promise<GetRationsResponse> {
        return (await alltisApiV2.get('/silos/rations')).data;
    }

    /**
     * Create a new ration type
     *
     * @param ration - ration to be added
     * @returns created ration
     */
    async createRation(ration: CreateRationRequest): Promise<Ration> {
        return (await alltisApiV2.post<Ration>('/silos/rations', ration)).data;
    }

    /**
     * Updates an existing ration
     *
     * @param id - ration identification to update
     * @param ration - New ration information
     * @returns Updated ration information
     */
    async updateRation(id: string, ration: Partial<UpdateRationRequest>): Promise<Ration> {
        return (await alltisApiV2.put(`/silos/rations/` + id, ration)).data;
    }

    /**
     * Delest an existing ration
     *
     * @param id - ration identification to delete
     */
    async deleteRation(id: string): Promise<void> {
        await alltisApiV2.delete('/silos/rations/' + id);
    }
}
