import { Module } from 'lib';
import { Container } from 'inversify';
import { WarehouseApi } from './api/WarehouseApi';
import { FeedApi } from './api/FeedApi';
import { SiloApi } from './api/SiloApi';

export class WarehouseModule implements Module {
    bindDependencies(container: Container): void {
        container.bind(WarehouseApi).toSelf();
        container.bind(FeedApi).toSelf();
        container.bind(SiloApi).toSelf();
    }
}
