import CameraVideoSheet from '@/camera-video/components/CameraVideoSheet';
import { CameraSource } from '@/camera-video/entities/CameraSource';
import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import Alert from '@/shared/components/ui/Alert';
import { List } from '@/shared/components/ui/List';
import { Sheet } from '@/shared/components/ui/Sheet';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { FlatListProps, Alert as RNAlert } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Separator, Stack } from 'tamagui';
import { DEFAULT_FETCH_INTERVAL } from '../../../../config/constants/fetchConfig';
import { useNewUIEnabled } from '../../../../config/theme/hooks/useNewUIEnabled';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';
import ChickenHouseItem from '../PlaceInformation/HouseItem';

type Props = Pick<FlatListProps<unknown>, 'ListEmptyComponent'>;

const PlacesList = (props: Props) => {
    const isNewUIEnabled = useNewUIEnabled();
    const safeArea = useSafeAreaInsets();

    const isLarge = useLargeLayout();

    const chickenHouses = useChickenHouses({ refreshInterval: DEFAULT_FETCH_INTERVAL });

    const isRemoteServer = useCameraServer((store) => store.networkConfig?.shouldUseRemoteServer);

    const cameraSheet = useRef<Sheet>(null);
    const [selectedCamera, setSelectedCamera] = useState<CameraSource>();

    function onCameraPress(camera: CameraSource | undefined) {
        if (camera) {
            setSelectedCamera(camera);

            return cameraSheet.current?.expand();
        }

        if (isRemoteServer) {
            return RNAlert.alert(
                'Câmera não disponível',
                'As câmeras não estão disponíveis no modo remoto. Conecte-se localmente à rede wifi para ver as câmeras dos núcleos.'
            );
        }
    }

    return (
        <Stack flex={1}>
            <Stack $platform-web={{ px: 16 }} flex={1}>
                <List
                    data={chickenHouses.data || []}
                    contentContainerStyle={{
                        paddingVertical: 22,
                    }}
                    estimatedItemSize={100}
                    renderItem={({ item }) => (
                        <Stack pb={isLarge ? 12 : 0} flex={1}>
                            <ChickenHouseItem data={item} isLoading={chickenHouses.isLoading} onSelectCamera={onCameraPress} />
                        </Stack>
                    )}
                    ItemSeparatorComponent={() => (
                        <Separator $platform-native={{ my: 20 }} $platform-web={{ border: 'none', my: 12 }} />
                    )}
                    ListEmptyComponent={props.ListEmptyComponent}
                    numColumns={isLarge ? 3 : 1}
                />
            </Stack>

            {!!chickenHouses?.dataUpdatedAt && (
                <Alert
                    message={`Atualizado em ${dayjs(chickenHouses?.dataUpdatedAt).format('DD [de] MMMM [às] HH:mm')}`}
                    severity='warn'
                    icon='clock'
                    borderBottomEndRadius={0}
                    borderBottomLeftRadius={0}
                    alignItems='center'
                    jc='center'
                    textProps={{
                        flex: 0,
                    }}
                    pb={isNewUIEnabled ? safeArea.bottom || undefined : undefined}
                />
            )}

            <CameraVideoSheet bottomSheetRef={cameraSheet} source={selectedCamera} onClose={() => setSelectedCamera(undefined)} />
        </Stack>
    );
};

export default PlacesList;
