import { LinearGradient } from 'expo-linear-gradient';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack, Text } from 'tamagui';
import { useDrawerConstants } from '../../../hooks/useDrawerConstants';
import { DrawerRoute } from '../../../types';

type Props = {
    item: DrawerRoute;
    onPress: (item: DrawerRoute) => void;
    isActive?: boolean;
};

const DrawerItem = ({ item, isActive, onPress }: Props) => {
    const { paddingX } = useDrawerConstants();

    const backgroundColor = isActive ? ['#678D41', '#A1CC60'] : ['transparent', 'transparent'];
    const iconColor = isActive ? '#9AC364' : '#657e83';

    const textColor = isActive ? '#D6ECC0' : '#657e83';

    return (
        <TouchableOpacity activeOpacity={0.8} onPress={() => onPress(item)}>
            <LinearGradient start={[0, 1]} end={[1, 0]} style={{ height: 50 }} colors={backgroundColor}>
                <Stack
                    flexDirection='row'
                    alignItems='center'
                    flex={1}
                    px={paddingX}
                    hoverStyle={{ background: '#657e8320' }}
                    transition='.2s'
                >
                    <Stack width={20}>{item.icon({ color: iconColor, size: 10, focused: false })}</Stack>
                    <Text color={textColor} fontSize={14} ml={18} fontWeight='500'>
                        {item.title}
                    </Text>
                </Stack>
            </LinearGradient>
        </TouchableOpacity>
    );
};

export default memo(DrawerItem);
