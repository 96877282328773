import { Feather } from '@expo/vector-icons';
import { NetInfoStateType, useNetInfo } from '@react-native-community/netinfo';
import React from 'react';
import { StackProps, Text, XStack, useTheme } from 'tamagui';

type Props = StackProps;

const WifiName = (props: Props) => {
    const theme = useTheme();
    const netInfo = useNetInfo();

    if (netInfo.type !== NetInfoStateType.wifi || !netInfo.details?.ssid) return null;

    return (
        <XStack jc='center' ai='center' {...props}>
            <Feather name='wifi' color={theme.secondary900.get() as string} />

            <Text color='$secondary900' textAlign='center' fontSize={12} ml={4}>
                {netInfo.details?.ssid}
            </Text>
        </XStack>
    );
};

export default WifiName;
