import { TamaguiProvider } from '@tamagui/web';
import { PropsWithChildren } from 'react';
import { useThemeConfig } from './tamagui.config';
import { PortalProvider } from 'tamagui';

export function ThemeProvider(props: PropsWithChildren) {
    const themeConfig = useThemeConfig();

    return (
        <PortalProvider>
            <TamaguiProvider config={themeConfig} defaultTheme='light'>
                {props.children}
            </TamaguiProvider>
        </PortalProvider>
    );
}
