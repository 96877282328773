import { useQuery } from '@tanstack/react-query';
import { DEFAULT_FETCH_INTERVAL } from '../../../../config/constants/fetchConfig';
import { cameraScanApi } from '../../api/config/cameraScanApi';
import { CameraScanResult } from '../../entities/CameraScanResult';
import { CameraScanStatus } from '../../entities/CameraScanStatus';
import { useCameraServer } from '../../store/CameraServerStore';
import { timestampToDate } from '../../utils/date';

type ScanResponse = {
    start: number;
    end?: number;
    status: CameraScanStatus;
};

export function useCameraScan() {
    const ipAddress = useCameraServer(({ networkConfig }) => networkConfig?.ip);

    const { scanResult, onCameraScan } = useCameraServer(({ scanResult, onCameraScan }) => ({
        scanResult,
        onCameraScan,
    }));

    return useQuery<CameraScanResult>({
        queryKey: ['camera-scan'],
        queryFn: async () => {
            const { data } = await cameraScanApi.get<ScanResponse>('/scan/status');

            const result: CameraScanResult = {
                status: data.status,
                start: timestampToDate(data.start) as Date,
                end: timestampToDate(data.end),
            };

            onCameraScan(result);

            return result;
        },
        placeholderData: scanResult,
        refetchInterval: DEFAULT_FETCH_INTERVAL,
        refetchIntervalInBackground: false,
        enabled: !!ipAddress,
    });
}
