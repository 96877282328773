import React from 'react';
import { Rect } from 'react-native-svg';

type Props = {
    percentage: number;
    width: number;
};

const SiloPercentageFill = ({ percentage, width }: Props) => {
    return (
        <Rect y='-150' x={-10} width={width + 30} height={(percentage || 0) + '%'} transform={`rotate(180, ${width / 2}, 35)`} />
    );
};

export default SiloPercentageFill;
