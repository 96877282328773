import Filters from '@/shared/components/ui/Filters';
import { UseFilter } from '@/shared/hooks/useFilter';
import { SiloDeviceStatus, SiloLevelStatus } from '@/warehouse/entities/WarehouseLevel';
import { SilosFilterState } from '@/warehouse/hooks/silos/useFilterSilos';
import { useSilos } from '@/warehouse/hooks/silos/useSilos';
import React, { useMemo } from 'react';
import SiloStatusIcon from '../../WarehouseStatus/SiloStatusIcon';

type Props = {
    filter: UseFilter<SilosFilterState>;
};

const SiloFilters = ({ filter }: Props) => {
    const { data } = useSilos();

    const siloNames = useMemo(() => data?.silos.map((s) => s.name) || [], [data]);

    return (
        <Filters>
            <Filters.Multiple
                label='Silo'
                items={siloNames.map((name) => ({ label: name, value: name }))}
                {...filter.getFilterProps('silos')}
            />

            <Filters.Multiple
                label='Status'
                items={[
                    {
                        label: 'Normal',
                        value: 'NORMAL' as SiloLevelStatus,
                        icon: <SiloStatusIcon deviceStatus='ACTIVE' levelStatus='NORMAL' />,
                    },
                    {
                        label: 'Crítico',
                        value: 'CRITICAL' as SiloLevelStatus,
                        icon: <SiloStatusIcon deviceStatus='ACTIVE' levelStatus='CRITICAL' />,
                    },
                    {
                        label: 'Inativo',
                        value: 'INACTIVE' as SiloDeviceStatus,
                        icon: <SiloStatusIcon deviceStatus='INACTIVE' />,
                    },
                    {
                        label: 'Desconectado',
                        value: 'DISCONNECTED' as SiloDeviceStatus,
                        icon: <SiloStatusIcon deviceStatus='DISCONNECTED' />,
                    },
                ]}
                {...filter.getFilterProps('status')}
            />
        </Filters>
    );
};

export default SiloFilters;
