import { AuthenticationModule } from '@/authentication/AuthenticationModule';
import { CameraVideoModule } from '@/camera-video/CameraVideoModule';
import { ChickenHouseModule } from '@/chicken-house/ChickenHouseModule';
import { WarehouseModule } from '@/warehouse/WarehouseModule';
import { Container } from 'inversify';

export const container = new Container();

const modules = [CameraVideoModule, ChickenHouseModule, AuthenticationModule, WarehouseModule];

modules.forEach((module) => new module().bindDependencies(container));
