import { toFixedOrNot } from '@/shared/utils/number';
import React from 'react';
import { Path } from 'react-native-svg';
import { Coords, getPercentage, interpolateY } from './helpers';

type Props = {
    width: number;
    height: number;
    data: { x: number; y: number }[];
};

const SiloContentPath = ({ data, height, width }: Props) => {
    const getPoints = () => {
        const points: Array<Coords> = [];

        const startY = interpolateY(0, {
            from: { x: data[0].x, y: data[0].y },
            to: { x: data[1].x, y: data[1].y },
        });

        const endY = interpolateY(100, {
            from: { x: data[data.length - 2].x, y: data[data.length - 2].y },
            to: { x: data[data.length - 1].x, y: data[data.length - 1].y },
        });

        points.push(
            { x: 0, y: 100 - startY }, // go to left start
            { x: 0, y: 100 }, // go to left bottom
            { x: 100, y: 100 }, // bo to right bottom
            { x: 100, y: 100 - endY } // go to right top
        );

        [...data].reverse().forEach((point) => points.push({ x: point.x, y: 100 - point.y }));

        points.push({ x: 0, y: 100 - startY }); // go to left start

        return points;
    };

    const getPathLines = () => {
        const points = getPoints();

        let path = points.reduce((result, point) => {
            const x = toFixedOrNot(getPercentage(point.x, width / 2), 1);
            const y = toFixedOrNot(getPercentage(point.y, height), 1);

            if (!result) {
                return `M${x} ${y}`;
            }

            return `${result} L${x} ${y}`;
        }, '');

        path += '  Z';

        return path;
    };

    const offset = 5;

    return (
        <>
            <Path d={getPathLines()} transform={`translate(-${offset}, 0)`} />
            <Path d={getPathLines()} transform={`scale(-1, 1) translate(-${width - offset}, 0)`} />
        </>
    );
};

export default SiloContentPath;
