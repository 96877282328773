import { Platform } from 'react-native';
import { MMKV } from 'react-native-mmkv';
import { StateStorage } from 'zustand/middleware';
import { config } from '../../../../config/env';

const db = new MMKV({
    id: '@alltis/authentication-store-db',
    encryptionKey: Platform.OS === 'web' ? undefined : config.database.mmkv.encryptionKey,
});

export function createAuthStorage(): StateStorage {
    return {
        getItem: (name) => db.getString(name) || null,
        removeItem: (name) => db.delete(name),
        setItem: (name, value) => db.set(name, value),
    };
}
