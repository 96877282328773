import dayjs from 'dayjs';
import { useState } from 'react';

export type ReportFilterData = {
    startDate: Date;
    endDate: Date;
    entityIds: string[];
};

export function useReportFilter(initialFilter?: ReportFilterData) {
    const [filter, onChange] = useState<ReportFilterData>(
        initialFilter || {
            startDate: dayjs().subtract(8, 'days').startOf('day').toDate(),
            endDate: dayjs().endOf('day').toDate(),
            entityIds: [],
        }
    );

    return {
        data: filter,
        onFilterChange: (newFilter: Partial<ReportFilterData>) => onChange({ ...filter, ...newFilter }),
    };
}
