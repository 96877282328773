import Report from '@/reports/screens/Report';
import { ReportProps } from '@/reports/screens/Report/ReportProps';
import { useSiloConsumptionReport } from '@/warehouse/hooks/report/useWarehouseReport';
import React from 'react';
import ConsumptionReportContent from '../ReportContent/ConsumptionReportContent';

type Props = Pick<ReportProps, 'filter'> & {
    enabled: boolean;
};

const ConsumptionReport = ({ filter, enabled }: Props) => {
    const report = useSiloConsumptionReport(
        {
            endDate: filter.data.endDate,
            startDate: filter.data.startDate,
            warehouseIds: filter.data.entityIds,
        },
        { enabled }
    );

    return (
        <Report
            type='silo-consumption'
            title='Relatório de Consumo'
            data={report.data || []}
            filter={filter}
            renderReportItem={(item) => <ConsumptionReportContent report={item} />}
            options={{ hideFilters: true }}
            status={{ isError: report.isError, isLoading: report.isLoading }}
        />
    );
};

export default ConsumptionReport;
