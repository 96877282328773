import ViewState from '@/shared/components/ui/ViewState';
import React from 'react';
import AquaIcon from '../AquaIcon';

type Props = {
    loading?: boolean;
    isEmpty: boolean;
};

const AquaViewState = ({ isEmpty, loading }: Props) => {
    return (
        <ViewState
            loading={loading}
            empty={{
                enabled: isEmpty,
                title: 'Nenhum aviário selecionado',
                message: 'Selecione uma aviário para ver os detalhes da água',
                renderIcon: () => <AquaIcon value={0} hideLabel />,
            }}
        />
    );
};

export default AquaViewState;
