import { Aqua } from '@/aqua/entities/Aqua';
import SectionContainer from '@/shared/components/ui/SectionContainer';
import React from 'react';
import { Stack } from 'tamagui';
import AquaConsumptionChart from '../../AquaConsumptionChart';
import BasicAquaInfo from '../../BasicAquaInfo';

type Props = {
    aqua: Aqua;
};

const AquaDetailsInformation = ({ aqua }: Props) => {
    return (
        <Stack>
            <SectionContainer title='Operação'>
                <BasicAquaInfo aqua={aqua} />
            </SectionContainer>

            <SectionContainer title='Consumo de água' containerProps={{ px: 0 }}>
                <AquaConsumptionChart aqua={aqua.consumption.historic} />
            </SectionContainer>
        </Stack>
    );
};

export default AquaDetailsInformation;
