import { useCheckHasWifi } from '@/chicken-house/hooks/wifi/useCheckHasWifi';
import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import React from 'react';
import Animated from 'react-native-reanimated';
import CameraScanAlert from './CameraScanAlert';
import DnsScanAlert from './DnsScanAlert';
import { StepAlertProps } from './StepAlertProps';
import WifiName from './WifiName';
import WifiNotValidAlert from './WifiNotValidAlert';
import NotConnectedAlert from './NotConnectedAlert';

type Props = StepAlertProps;

const StepAlerts = ({ animationHeight = 250, ...props }: Props) => {
    const scanStep = useCameraServer((state) => state.scanStep);
    const wifiCheck = useCheckHasWifi();

    if (!wifiCheck.isValid) {
        if (wifiCheck.reason === 'no_connection') {
            return <NotConnectedAlert animationHeight={animationHeight} {...props} />;
        }

        return <WifiNotValidAlert animationHeight={animationHeight} {...props} />;
    }

    return (
        <Animated.View style={{ flex: 1, justifyContent: 'center' }}>
            {scanStep === 'scanning_dns' ? (
                <DnsScanAlert animationHeight={animationHeight} {...props} />
            ) : (
                <CameraScanAlert animationHeight={animationHeight} {...props} />
            )}

            <WifiName mt={24} />
        </Animated.View>
    );
};

export default StepAlerts;
