import React from 'react';
import { Rect } from 'react-native-svg';
import { SiloPartProps } from './props';

export const SiloBody = (props: SiloPartProps) => {
    const height = props.height;
    const width = props.width;

    return <Rect width={width} height={height} strokeLinejoin='round' />;
};
