import { useEffect } from 'react';
import { useSetupPushNotifications } from '../../../config/notifications/useSetupPushNotifications';
import { useNewUIEnabled } from '../../../config/theme/hooks/useNewUIEnabled';
import { HomeTabs } from '../HomeTabs';
import { MainDrawer } from './MainDrawer';

export const MainScreen = () => {
    const isNewUI = useNewUIEnabled();

    const notifications = useSetupPushNotifications();

    useEffect(() => {
        notifications.registerForPushNotificationsAsync();
    }, []);

    if (isNewUI) return <MainDrawer />;

    return <HomeTabs />;
};
