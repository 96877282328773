import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack, Text, XStack } from 'tamagui';
import Checkbox from '../../../Checkbox';
import { MultiSelectItem } from '../../MultiSelectProps';

type Props = {
    item: MultiSelectItem;
    isSelected: boolean;
    onSelect: (item: MultiSelectItem) => void;
};

const SelectItem = ({ isSelected, item, onSelect }: Props) => {
    return (
        <TouchableOpacity onPress={() => onSelect(item)} activeOpacity={0.6}>
            <XStack
                px={24}
                py={18}
                ai='center'
                cursor='pointer'
                hoverStyle={{
                    bg: '#f4f6f9',
                }}
            >
                {item.icon && <Stack mr={8}>{item.icon}</Stack>}

                <Stack flex={1}>
                    <Text fontSize={16} textAlign='left' fontWeight='bold' color='$secondary400' mb={2}>
                        {item.label}
                    </Text>

                    {item.caption && (
                        <Text fontSize={12} textAlign='left' color='$secondary900'>
                            {item.caption}
                        </Text>
                    )}
                </Stack>

                <Stack pointerEvents='none'>
                    <Checkbox value={isSelected} />
                </Stack>
            </XStack>
        </TouchableOpacity>
    );
};

export default memo(SelectItem);
