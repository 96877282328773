import { SiloReport } from '@/warehouse/entities/WarehouseReportData';
import { WarehouseReportForm } from '@/warehouse/entities/WarehouseReportForm';
import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';
import { SiloHistoryResponse, useSiloReportMapper } from './useSiloReportMapper';

type QueryOptions = {
    enabled?: boolean;
};

export function useSiloConsumptionReport(filter: WarehouseReportForm, { enabled }: QueryOptions) {
    const warehouses = filter.warehouseIds?.join(',') || '';

    const mapper = useSiloReportMapper();

    return useQuery<SiloReport[]>(
        ['silo-consumption-report', filter],
        async () => {
            const consumptionResponse = await alltisApiV2.get<SiloHistoryResponse>(`/silos/historical`, {
                params: {
                    ids: warehouses,
                    initial_period: filter.startDate,
                    final_period: filter.endDate,
                },
            });

            return consumptionResponse.data?.map((data) => ({
                warehouseName: data.name,
                conjugatedWarehouseName: data.group_name,
                unit: data.prefer_tons ? 'tons' : 'perc',
                data: mapper.mapToReportData(data),
            }));
        },
        {
            enabled: !!filter.warehouseIds?.length && !!filter.endDate && !!filter.startDate && enabled,
        }
    );
}

export function useSiloResupplyReport(filter: WarehouseReportForm, { enabled }: QueryOptions) {
    const warehouses = filter.warehouseIds?.join(',') || '';

    const mapper = useSiloReportMapper();

    return useQuery<SiloReport[]>(
        ['silo-resupply-report', filter],
        async () => {
            const resupplyResponse = await alltisApiV2.get<SiloHistoryResponse>(`/silos/historical/resupply`, {
                params: {
                    ids: warehouses,
                    initial_period: filter.startDate,
                    final_period: filter.endDate,
                },
            });

            return resupplyResponse.data?.map((data) => ({
                warehouseName: data.name,
                conjugatedWarehouseName: data.group_name,
                unit: data.prefer_tons ? 'tons' : 'perc',
                data: mapper.mapToReportData(data),
            }));
        },
        {
            enabled: !!filter.warehouseIds?.length && !!filter.endDate && !!filter.startDate && enabled,
        }
    );
}
