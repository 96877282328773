import React, { ReactNode, cloneElement } from 'react';
import { Text, TextProps } from 'tamagui';

type Props = Omit<TextProps, 'children'> & {
    children: string | ReactNode;
};

const TextOrNode = (props: Props) => {
    if (typeof props.children !== 'string') {
        const { color, textAlign, fontSize } = props;

        return cloneElement(props.children as JSX.Element, { color, textAlign, fontSize });
    }

    return <Text {...props}>{props.children}</Text>;
};

export default TextOrNode;
