import { SiloSite } from '@/warehouse/entities/Silo';
import Feather from '@expo/vector-icons/Feather';
import React from 'react';
import { Stack, StackProps, Text } from 'tamagui';

type Props = {
    site: Partial<SiloSite> | undefined;
    stackProps?: StackProps;
};

const SiteName = ({ site, stackProps }: Props) => {
    if (!site?.name) return;

    return (
        <Stack fd='row' ai='center' {...stackProps}>
            <Feather name='home' color='#A0AEC0' />

            <Text color='#A0AEC0' ml={4} numberOfLines={2}>
                {site?.name?.replace(/(\r\n|\n|\r)/gm, '')}
            </Text>
        </Stack>
    );
};

export default SiteName;
